import styles from '../../Topics/articles/styles.module.css';
export const Women = () => {
  return <section className={styles.sectionContainer}>
    <h2>Overview</h2>
    <p>At FRND, we believe that women deserve equal access to the opportunities the internet provides. We understand that you have to feel safe to meaningfully participate in a community. Learn more about tools that let you confidently connect with friends</p>
    <p>On our platform, the safety of female users is our top priority. We empower them by providing control over their connections and friendships. Through periodic campaigns, we educate our female users on how to navigate the platform safely.</p>
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr10.png" alt="safety-heading" />
    <p>If any female user feels unsafe with another user, they can directly contact us via call. Our team will promptly address and resolve the issue.</p>
    <p>Additionally, if any user solicits personal information, engages in nudity, inappropriate conversation, or causes discomfort, we encourage immediate reporting through the designated option.</p>
    
  </section>
}