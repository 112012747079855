import React from 'react'

const Play = ({onPlayerClick, style}) => {
  return (
      <svg style={style} className="button" viewBox="0 0 60 60" onClick={onPlayerClick}>
        <polygon style={{fill: 'white'}} points="0,0 50,30 0,60" />
      </svg>
  )
}

export default Play