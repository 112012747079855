import styles from './styles.module.css';
export const Anonimity = () => {
  return <section className={styles.sectionContainer}>
    <h2>Overview</h2>
    <p>Anonymity is key to ensuring a secure and inclusive atmosphere on FRND. We respect our users' privacy by allowing them to select any display name they prefer, without requiring them to disclose their real identity. This flexibility empowers individuals to express themselves authentically while maintaining personal privacy.</p>

    <p>Furthermore, to protect user privacy, we employ AI-generated avatars for profile pictures. These avatars offer a creative and fun way for users to represent themselves without sharing their actual photos. By using AI avatars, we prioritize anonymity, safeguarding our users' identities while fostering meaningful connections on our platform.</p>
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr17.png" alt="safety-heading" />

  </section>
}