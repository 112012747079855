import { Accordion } from "./components/Accordion";
import './faqs.css';
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'

export const FAQsComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_JOIN_US_FAQS);
  registerStrings(strings);
  const faqs = [
    {
      question: 'What is the interview process like for the program?',
      answer: 'The moment you apply for the program, our HRs will screen your responses and reach out to you. Here’s the overview of the interview rounds :',
      bullets: [
        'HR Round - An initial screening round to assess feasibility and culture',
        'Task assessment - An assignment round that test your critical thinking skills',
        'Product round - A technical/ product round to tinkle your product senses ',
        'Final discussion - Round with a co-founder to assess your learnings  '
      ]
    },
    {
      question: 'What are some tips on how to crack the APM program?',
      bullets: [
        'App Dissection - Know the in-and-outs of the app, who are the user personas we are catering to, what are all the flows from the users POV. The better you know the better it’ll go.',
        'Intent to thrive - Your intent to thrive even at days when things are not going your way will play a key role in determining if you have the grit to succeed',
        'Product mindset - Your ability to think holistically about the product. Understand the user’s pain points, the business goals, and how features create value, backed by data and user insights.',
      ]
    },

  ];
  return (
    <div className="faqs-section-container">
      <div className="faqs-container">
        <h1>{strings.heading}</h1>
        {faqs.map((faq, index) => (
          <div key={index}>
            <Accordion question={faq.question} answer={faq.answer}>
              <ul>
                {faq.bullets.map((point) => <li><b>{point.split("-")[0]} - </b>{point.split("-").slice(1).join("-")}</li>)}
              </ul>
            </Accordion>
            {index < faqs.length - 1 && <hr data-aos="fade-up" data-aos-duration="1000" />}
          </div>
        ))}

      </div>
    </div>
  )
}