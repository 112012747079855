import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'
import styles from './styles.module.css';

export const VideosGrid = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_MOTTO);
  registerStrings(strings);
  const GRID_ITEMS = [
    {
      title: 'High-Energy Vibes 🏃',
      description: 'It’s fast-paced, super collaborative, and always keeps you on your toes. Make sure to keep up!',
      video: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/HighEnergyVibesC.mp4'
    },
    {
      title: 'Work Hard, Play Harder 🎲',
      description: 'Whether it’s Fun Fridays, team sports, or hanging out with office pets, we’re all about mixing work with play!',
      video: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/WorkHardPlayHardC.mp4'
    }
  ]
  return (
    <div className={styles.gridContainer}>
      {
        GRID_ITEMS.map(({ title, description, video }, index) => {
          return <div className={styles.gridItem}>
            <video controls alt="grid_item" src={video} />
            <div className={styles.gridContent}>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        })
      }
    </div>

  )
}