import { getCookie, setCookie } from "../cookie";
export const getCodeFromLanguage = (lang) => {
  return LANGUAGES_DATA.find(langObj => langObj.label === lang)?.code;
}
export const getLanguageFromCode = (code) => {
  return LANGUAGES_DATA.find(langObj => langObj.code === code).label;
}

export const LANGUAGES_DATA = [
  {
    label: 'English',
    value: 'English',
    code: 'en'
  },
  {
    label: 'हिंदी',
    value: 'Hindi',
    code: 'hi'
  },
  {
    label: 'తెలుగు',
    value: 'Telugu',
    code: 'tel'

  },
  {
    label: 'தமிழ்',
    value: 'Tamil',
    code: 'tam'
  },

];

const cookieLang = getCodeFromLanguage(getCookie('lang'));

let language = (new URLSearchParams(window.location.search)).get("lang") || cookieLang || 'en';
if(cookieLang !== language) {
  setCookie('lang', getLanguageFromCode(language))
}
let data = [];

export const registerStrings = (strings, setStrings) => {
  data.push({ strings, setStrings })
  strings.setLanguage(language)
}

export const applyLanguage = (code) => {
  data.forEach(({ strings, setStrings }) => {
    strings.setLanguage(code)
    setStrings(strings)
  })
}

