import { useEffect } from "react";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Home/Footer";
import JoinUs from "../../Components/Home/JoinUs";
import USP from "../../Components/Home/USP";

export const Careers = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
  <div style={{paddingTop: 72}}>
    <Header forceActive />
    <JoinUs />
    <USP />
    <Footer />
  </div>
  )}