import { TOPICS } from "../data"
import styles from './styles.module.css';
import cta from '../cta.svg';
import { useNavigate } from "react-router-dom";
export const TopicsHome = () => {
  const navigate = useNavigate()
  return <div className={styles.container}>
    <div className={styles.cardsContainer}>
      <h1>Topics</h1>
      {TOPICS.map((topic) => {
        return (
          <div onClick={() => navigate(topic.slug)} className={styles.cardContainer}>
            <img src={topic.imageLink} alt={topic.title} className={styles.cardImage} />
            <h2>{topic.title}</h2>
            <p>{topic.description}</p>
            <div className={styles.ctaLink}>
              <img src={cta} alt={'cta'} />
              <p>Learn more</p>
            </div>
          </div>
        )
      })}
    </div>
   
  </div>
}