import Image6 from './images/1.png'
import Image2 from './images/2.png'
import Image7 from './images/3.png'
import Image5 from './images/4.png'
import Image9 from './images/5.png'
import Image3 from './images/6.png'
import Image8 from './images/7.png'
import Image4 from './images/8.png'
import Image1 from './images/9.png'
import { HashLink } from 'react-router-hash-link'
import styles from './hero.module.css';
import { registerStrings } from '../../../modules/localisation/LocalisationModule'
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { useState } from 'react'

const IMAGES_GRID = [
  [
    Image1,
    Image2,
  ],
  [
    Image3,
    Image4,
    Image5,
  ],
  [
    Image6,
    Image7,
    Image8,
    Image9,
  ]
]

export const HeroComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.GAME_EXPERT_JOIN_US_HERO);
  let strings2 = new LocalizedStrings(LANGUAGE_DATA.GAME_EXPERT_JOIN_US_FORM);
  registerStrings(strings2);
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    gender: 'F',
    referredBy: '',
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const validateMobileNumber = () => {
    const mobileNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    return mobileNumberRegex.test(formData.mobileNumber);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!formData.name || !formData.gender || !formData.mobileNumber || !formData.referredBy || !formData.language) {
      setMessage("*All the fields are mandatory")
      return;
    } else if(!validateMobileNumber(formData.mobileNumber)) {
      setMessage("*Please enter a valid mobile number")
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "OAuth 952");
    var formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("mobile_no", formData.mobileNumber);
    formdata.append("referred_by", formData.referredBy);
    formdata.append("gender", formData.gender);
    formdata.append("language", formData.language);
    formdata.append("type", "Dumbcharades");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: formdata
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL
    fetch(`${BASE_URL}/joinUs`, requestOptions).then((res) => res.json())
    .then((data) => {
      if(data.error) {
        setMessage(data.error.err_message)
      } else {
        // setMessage(data.message);
        window.alert("Your registration was successful! Our team will reach out to you in 24 hours")
        setFormData({
          name: '',
          mobileNumber: '',
          gender: 'F',
          referredBy: '',
        })
      }
    })
    .finally(() => {
      window.fbq('track', 'SubmitApplication');
    })

  };
  registerStrings(strings);
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroContent}>
        <h1 data-aos="fade-up" data-aos-duration="1000" className={styles.heroHeading}>{strings.heading}</h1>
        <p data-aos="fade-up" data-aos-duration="1000" className={styles.heroSubheading}>{strings.subheading}</p>


        <form data-aos="fade-up" data-aos-duration="1000" onSubmit={handleSubmit}>
          <div className={styles.formTextFieldsContainer}>
            <input
              type="text"
              id="name"
              className={styles.formTextField}
              name="name"
              placeholder={strings2.name}
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="text"
              id="mobileNumber"
              className={styles.formTextField}
              maxLength={10}
              name="mobileNumber"
              placeholder={strings2.number}
              value={formData.mobileNumber}
              onChange={handleChange}
            />
         
          </div>

          <div className={styles.formTextFieldsContainer}>
          <select
              id="language"
              name="language"
              className={styles.formTextField}
              value={formData.language}
              onChange={handleChange}
            >
              <option value="" disabled selected>*Select your language</option>
              <option value="Hindi">Hindi</option>
              <option value="Tamil">Tamil</option>
              <option value="Telugu">Telugu</option>
              <option value="Odia">Odia</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Kannada">Kannada</option>
              <option value="Malayalam">Malayalam</option>
              <option value="Other">Other</option>
            </select>
            <input
              type="text"
              id="referredBy"
              className={styles.formTextField}
              name="referredBy"
              title='how did you get to know about us?'
              placeholder={strings2.referredBy}
              value={formData.referredBy}
              onChange={handleChange}
            />
            </div>
            <div className={styles.formTextFieldsContainer}>

          <div>

          <div>
            <div >
              <label className={styles.radioLabel}>{strings2.gender}:</label>
            </div>
            <div className='radio-options-container'>
              <label className={styles.radioLabel}>
                {strings2.male}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="M"
                  checked={formData.gender === 'M'}
                  onChange={handleChange}
                />
              </label>
              <label className={styles.radioLabel}>
                {strings2.female}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="F"
                  checked={formData.gender === 'F'}
                  onChange={handleChange}
                />
              </label>
              <label className={styles.radioLabel}>
                {strings2.other}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="O"
                  checked={formData.gender === 'O'}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
          </div>
          <button className={styles.button} type="submit">{strings2.submitButton}</button>
          </div>
         
          
          <p className={styles.message}>{message}</p>
        </form>
      </div>
      <div className={styles.heroGrid}>
        {
          IMAGES_GRID.map((images, index) => {
            return (
              <div key={index.toString()} className={styles.heroGridRow}>
                {images.map((imageUrl, key2) => <img key={key2} data-aos="zoom-in" data-aos-duration="1000" className={styles.heroGridImage} src={imageUrl} />)}
              </div>
            )
          })
        }
      </div>

    </div>
  )
}