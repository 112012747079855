import styles from './styles.module.css';
export const MediaSafetyGuidelines = () => {
  return <section className={styles.sectionContainer}>
    <h2>Overview</h2>
    <p>Ensuring the safety of our users during Voice and Video calls is one of our top priorities. Our dedicated team of human moderators work around the clock to monitor platform activity. In addition to this, we employ AI features and provide reporting options for all features of the app. This multi-layered approach ensures comprehensive oversight and prompt action against any concerning behavior on our platform.</p>
  
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr20.jpeg" alt="safety-heading" />
    <h3>Safety in Video Calls</h3>
    <p>Our commitment to safety is reinforced by the implementation of a third-party AI moderation tool, ensuring a secure environment for all users. The effectiveness of this approach is demonstrated by statistics showing active monitoring and protection on our platform.</p>
    <p>Users cannot take screenshots or record video calls.</p>
    <p>Any violence/hate symbols/nudity detected on the platform is promptly identified by our third-party AI moderation tool. Our moderation team swiftly reviews reported issues and takes appropriate action against the offending user.</p>
    <p>If you encounter any issues of this nature, report the user within the call and promptly end the call for your safety.</p>

    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr21.png" alt="safety-heading" />
    <h3>Safety in Voice Calls</h3>
    <p>Users are prohibited from engaging in inappropriate behavior or using offensive language on the platform. Our moderators are dedicated to maintaining a safe voice call experience for all users, working tirelessly around the clock. </p>
    <p>If you witness any such behavior, it is your responsibility to report the user. Your report enables us to take swift action against those who violate these guidelines.</p>
    
  </section>
}