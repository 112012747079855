import React, { useState, useEffect } from 'react';
import styles from './slick.module.css'; // Import CSS module
import Caret from './caret.svg';
import { TitleDivider } from '../components/titleDivider';


const DATA = [
  {
    photo: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Bhanu1.png',
    linkedin: 'https://www.linkedin.com/in/bpst13',
    position: 'Founder & CEO',
    name: 'Bhanu Pratap Singh Talwar'
  },
  {
    photo: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Harsh1.png',
    linkedin: 'https://www.linkedin.com/in/hvchhangani',
    position: 'Founder & CPO',
    name: 'Harshvardhan Chhangani'
  },
  {
    photo: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Danish1.png',
    linkedin: 'https://www.linkedin.com/in/s-danish',
    position: 'Lead Product Manager',
    name: 'Danish Nabeel'
  },
  {
    photo: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Aditya1.png',
    linkedin: 'https://www.linkedin.com/in/aditya-sonthalia',
    position: 'Revenue Lead',
    name: 'Aditya Sonthalia'
  },

]

export const Carousel = () => {
  // Duplicating first and last item to create infinite loop illusion
  // const extendedPhotos = [photos[photos.length - 1], ...photos, photos[0]];
  // const extendedPhotos = [photos[photos.length - 1], ...photos, photos[0]];

  const [finalData, setFinalData] = useState(DATA);
  const isMobile = window.innerWidth <= 600;

  const [currentIndex, setCurrentIndex] = useState(isMobile ? 0 : 1);  // Start from the first real slide
  const [isTransitioning, setIsTransitioning] = useState(false);

  const goToNext = () => {
    if (isTransitioning) return; // Prevent multiple clicks during transition
    // if (currentIndex === finalData.length - 2) {
    //   setFinalData([...finalData, ...DATA]);
    // }
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % DATA.length);

    setTimeout(() => {
      setIsTransitioning(false);
    }, 500); // Same duration as the CSS transition
  };

  const goToPrev = () => {
    if (isTransitioning) return; // Prevent multiple clicks during transition
    setIsTransitioning(true);
    if (currentIndex === 2) {
      // setFinalData([...DATA, ...finalData]);
      // setCurrentIndex((prevIndex) => prevIndex + DATA.length - 1);
    } else {
    }
    if (currentIndex === 0) {
      setCurrentIndex(DATA.length - 1);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex - 1));
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };

  // // Update currentIndex in an infinite loop
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === photos.length - 1 ? 0 : prevIndex + 1
  //     );
  //   }, 3000); // 3 seconds interval

  //   return () => clearInterval(interval);
  // }, [photos.length]);

  return (
    <div className={styles.carouselContainer}>

      <div className={styles.title}>
        <TitleDivider heading={"Meet the faces behind"} subheading={"success"} />
      </div>
      {true ? <div onClick={goToPrev} className={styles.leftArrow}>
        <img src={Caret} alt="left-arrow" />
      </div> : null}
      <div className={styles.carousel}>
        <div
          className={styles.carouselInner}
          style={{
            transform: `translateX(-${currentIndex * window.innerWidth * (isMobile ? 1 : 0.55)}px)`,
            transition: isTransitioning ? 'transform 0.5s ease-in-out' : 'none'
          }}
        >
          {finalData.map(({ photo, name, linkedin, position }, index) => (
            <div key={index} className={styles.slide}>
              <img className={currentIndex !== index ? styles.inactiveImage : styles.activeImage} src={photo} alt={`Slide ${index}`} />
              <div className={styles.detailsContainer}>
                <h1>{name}</h1>
                <p>{position}</p>
                <a href={linkedin}>Check out LinkedIn</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {true ? <div onClick={goToNext} className={styles.rightArrow}>
        <img src={Caret} alt="right-arrow" />
      </div> : null}
      <div className={styles.dotsContainer}>
        {DATA.map((_, index) => {
          return <div className={(currentIndex % DATA.length) === index ? styles.activeDot : styles.dot} />
        })}
      </div>
    </div>
  );
};


