import Slider from "react-slick";
import styles from './Carousel.module.css'
import './slick.css'
export const Carousel = ({height, images=[], style, onTouchStart}) => {
  const settings = {
    dots: true,
    dotsClass: `slick-dots ${height === 20 ? styles.slickDots20: styles.slickDots}`,
    infinite: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: function(i) {
      return <div className={`${styles.slickDot} slick-dot`} />
    }
  };
  return (
    <div onTouchStart={onTouchStart} className={`${styles.carouselContainer}`} style={{...style}} >
      <Slider {...settings}>
         {images.map((image, index) => <img key={index} className={`${styles.carouselImage} ${height === 50 && styles.carouselImage50}  ${height === 20 && styles.carouselImage20} ${height === 100 && styles.carouselImage100}`} src={image}/>)}
      </Slider>
    </div>
  );
}