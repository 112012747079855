import { openApp, openTrackedPlayStoreLink } from '../../../common/utils';
import { moreFrndsConfig } from '../config';
import AgeImg from '../../../images/age.png';
import LocationImg from '../../../images/location.png';
import './more_frnds.css';
import { useRef } from 'react';
import CarouselPoints from '../../Common/Carousel';

const MoreFrnds = () => {
    
    const slideEl = useRef(document.createElement("div"));

    const carouselHandler = (index) => {
        const width = document.getElementsByClassName('carousel-item')[0].offsetWidth;
        slideEl.current.style.transform = `translateX(-${index * width}px)`;
    }

    return (
        <div>
            <div className='more-frnd-title'>Meet more FRNDs</div>
            <div className='more-frnd-container'>
                <div className='more-frnd-carousel' ref={slideEl}>
                    {moreFrndsConfig.map(user => (
                        <div className='carousel-item more-frnd-section'>
                            <div style={{ marginTop: 16 }}><img height={160} src={user.imageUrl} /></div>
                            <div style={{ marginTop: 8 }} className='profile-data'>
                                <div className='profile-data-icon'><img height={16} src={AgeImg}/></div>
                                <div>{user.age} yrs</div>
                                <div style={{ marginLeft: 8 }} className='profile-data-icon'><img height={16} src={LocationImg}/></div>
                                <div>{user.location}</div>
                            </div>
                            <div onClick={openTrackedPlayStoreLink} className='more-frnd-link'>View Profile</div>
                        </div>
                    ))}
                </div>
            </div>
            <CarouselPoints onCarouselChange={carouselHandler} itemsCount={moreFrndsConfig.length} />
        </div>
    )
};

export default MoreFrnds;