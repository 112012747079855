import './profile_card.css';
import { profileConfig } from '../config';
import LocationImg from '../../../images/location.png';
import AgeImg from '../../../images/age.png';
import { openApp, openTrackedPlayStoreLink } from '../../../common/utils';

const ProfileCard = ({ userId }) => {
    const user = profileConfig.userData[userId];
    return <div className='profile-card'>
        <div className='profile-card-top'>
            <img className='profile-avatar' height={88} src={user.avatarImgUrl} />
            <div className='profile-follow' onClick={openTrackedPlayStoreLink}>Follow</div>
        </div>
        <div className='profile-details'>
            <div className='profile-name'>{user.name}</div>
            <div className='profile-data'>
                <div className='profile-data-icon'><img height={16} src={AgeImg}/></div>
                <div>{user.age} yrs</div>
                <div style={{ marginLeft: 8 }} className='profile-data-icon'><img height={16} src={LocationImg}/></div>
                <div>{user.location}</div>
            </div>
            <div className='profile-language'>Language: {user.languages}</div>
            <div className='profile-followers'>{user.followers} Followers</div>
        </div>
    </div>
}

export default ProfileCard;