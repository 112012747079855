import { Link } from 'react-router-dom';
import styles from '../../Topics/articles/styles.module.css';
export const ModerationPartner = () => {
  return <section className={styles.sectionContainer}>
    <Link target='_blank' to="https://hivemoderation.com/visual-moderation" className={styles.linkContainer}>
      <h2>HIVE MODERATION</h2>
      <div style={{display: 'flex', gap: 8}}>
        <div>Visit Website</div>
      </div>
    </Link>
  </section>
}