import './features.css';
import VoiceIcon from '../../../images/voice.png';
import VideoIcon from '../../../images/video.png';
import { openApp, openTrackedPlayStoreLink } from '../../../common/utils';


const Features = () => {
    return (<div>
        <div className='profile-features-title'>I am available from 6 PM on FRND App</div>
        <div className='profile-features-container'>
            <div className='profile-features-section'>
                <div className='profile-features-desc'>I will solve all your problems personally</div>
                <div onClick={openTrackedPlayStoreLink} className='profile-features-button' style={{ backgroundColor: '#48B2FF', marginTop: 16 }}>
                    <img height={24} style={{ marginRight: 6 }} src={VideoIcon} />
                    Video Call
                </div>
                <div onClick={openTrackedPlayStoreLink} className='profile-features-button' style={{ backgroundColor: '#985AFF', marginTop: 16 }}>
                    <img height={24} style={{ marginRight: 6 }} src={VoiceIcon} />
                    Voice Call
                </div>
            </div>
            <div className='profile-features-section'>
                <div className='profile-features-desc'>I will find you a match on my show</div>
                <div onClick={openTrackedPlayStoreLink} className='profile-features-button' style={{ backgroundColor: '#3ABC76', marginTop: 24 }}>Join my show</div>
            </div>
            <div className='profile-features-section'>
                <div className='profile-features-desc'>I talk about love, relationship & hearbreaks</div>
                <div onClick={openTrackedPlayStoreLink} className='profile-features-button' style={{ backgroundColor: '#48B2FF', marginTop: 24 }} >Chat with me</div>
            </div>
        </div>
    </div>);
}


export default Features;