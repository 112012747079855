import prob from './images/problem-solver.svg'
import acad from './images/acad.svg'
import data from './images/data.svg'
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'
import styles from './styles.module.css';
import { SlantHeading } from '../components/SlantHeading'

export const Motto = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_MOTTO);
  registerStrings(strings);
  const GRID_ITEMS = [
    {
      title: 'Academic Geniuses ',
      description: 'Graduates from top schools like IITs, NITs, BITs, or other prestigious institutions who can bring their sharp analytical skills to the table.',
      image: acad
    },
    {
      title: 'Data Enthusiasts',
      description: 'With 6+ months of experience in data analytics, consulting, or related fields, even without product experience is perfectly normal. We\'re focused on your drive to learn and grow.',
      image: data
    },
    {
      title: 'Problem Solver with heart',
      description: 'If you\'re passionate about working on something meaningful and creating lasting value, this program offers you the chance to do just that.',
      image: prob
    },
  ]
  return (
    <div className={styles.container}>
      <SlantHeading prefix={"Who"} heading={"should apply at APM Program?"} />
      <p className={styles.subheadings1}>Are you a curious, problem-solving powerhouse who loves the rush of a fast-paced environment? </p>
      <p className={styles.subheadings2}> If yes, FRND’s Associate Product Manager (APM) Program could be your calling!</p>
      <div className={styles.gridContainer}>
        {
          GRID_ITEMS.map(({ title, description, image }, index) => {
            return <div data-aos-duration="1000" data-aos="fade-up" data-aos-delay={`${index*500}`} className={styles.gridItem} style={{ marginTop: (2 - index) * 64, marginBottom: (index) * 64 }}>
              <img alt="grid_item" src={image} />
              <div className={styles.gridContent}>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
          })
        }
      </div>
      <button onClick={() => window.location.href = "https://form.typeform.com/to/sp1j82sU"} className={styles.button}>APPLY NOW</button>
      <p><b><i>Note :</i></b> If you already are an APM, we suggest you should apply for PM jobs at FRND from <a target="_blank" href="https://www.linkedin.com/company/frnd-app/jobs/">here</a> to work with us. </p>
    </div>

  )
}