import styles from '../../Topics/articles/styles.module.css';
import back from '../../back.png';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { COMMUNITIES } from '../../data';

export const ArticleContainer = () => {

  let { slug } = useParams();
  const navigate = useNavigate()

  const community = COMMUNITIES.find((community) => community.slug === slug);

  if (!community) {
    return <div className={styles.notFound}>404 Not Found</div>
  }
  const Component = community.component

  return <div className={styles.container}>
    <div onClick={() => navigate("/safety/communities")} className={styles.backContainer}>
      <img src={back} alt='backIcon' className={styles.backIcon} />
      <h3 className={styles.backText} >Back to Communities</h3>
    </div>
    <section className={styles.heroContainer}>
      <img src={community.imageLink} alt='backIcon' className={styles.heroImage} />
      <div className={styles.heroTextContainer}>
        <h1 className={styles.heroTitle}>
          {community.title}
        </h1>
        <p className={styles.heroDescription}>{community.description}</p>
      </div>
    </section>
    {Component && <Component />}
  </div>

}