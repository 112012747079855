
import React, { useEffect, useState } from 'react';
import styles from './transparency.module.css';
import { Accordian } from './Accordian';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement, Title } from "chart.js";
import { creatorsPieChartData, creatorsVideoObscenityOptions, flaggedCellsData, flaggedCreatorData, flaggedUserData, getBarOptions, getDoughnutOptions, getDoughnutTitlePlugin, pieChartOptions, tatPieChartData, usersPieChartData, usersVideoObscenityOptions } from './data';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Platform from './platform.png'
import Female from './female.png'
import Moderation from './moderation.png'
import { Loader } from '../../Components/Common/Loader';
import { getSafetyData } from '../../modules/networkCall';
import { ModalContext } from '../../Components/Common/Modal/context';


ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement, ChartDataLabels, Title);

export const Transparency = () => {
  const [data, setData] = useState(null);
  let { handleModal } = React.useContext(ModalContext);
  const getData = async () => {
    const data = await getSafetyData();
    setData(data?.trustAndSafetyData);
  }
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  if (!data) {
    return <div className={styles.loaderContainer} ><Loader /></div>
  }
  return (
    <div className={styles.container}>
      <div className={styles.heroContainer}>
        <h1 data-aos="fade-up" data-aos-duration="1000">Statistics</h1>
        <p data-aos="fade-up" data-aos-duration="1000">Creating a safe and secure digital platform</p>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <div className={styles.accordianContent}>
          <div className={`${styles.valuesContainer}`}>
            <h1 data-aos="fade-up" data-aos-duration="1000" className={styles.valuesTitle}>Our Core Values</h1>
            <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
              <div>
                <img src={Platform} className={styles.valueImage} />
                <h2 className={styles.valueHeading}>Safe & Inclusive Platform</h2>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
              <div>
                <img src={Female} className={styles.valueImage} />
                <h2 className={styles.valueHeading}>Female Friendly Community</h2>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
              <div>
                <img src={Moderation} className={styles.valueImage} />
                <h2 className={styles.valueHeading}>Proactive Moderation</h2>
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}>

            <h3>Flagged Calls</h3>
            <div className={styles.chartContainerSmall}>
              <Doughnut plugins={[getDoughnutTitlePlugin(data)]} options={getDoughnutOptions()} data={flaggedCellsData(data)} />
            </div>
            <p style={{ textAlign: 'center' }}>Calls identified in compliance<br /> with platform guidelines</p>
          </div>

        </div>
      </div>
      {/* <h1 data-aos="fade-up" data-aos-duration="1000" className={styles.valuesTitle}>Our Core Values</h1> */}
      {/* <div className={`${styles.sectionContainer} ${styles.valuesContainer}`}>
        <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
          <div>
            <img src={Platform} className={styles.valueImage} />
            <h2 className={styles.valueHeading}>Safe & Inclusive<br /> Platform</h2>
          </div>
          <p className={styles.valueText}>We condemn all forms of inappropriate behavior, aiming to create a safe and welcoming environment for everyone.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
          <div>
            <img src={Female} className={styles.valueImage} />
            <h2 className={styles.valueHeading}>Female Friendly <br /> Community</h2>
          </div>
          <p className={styles.valueText}>We have implemented specialized features and strong measures like enhanced reporting tools, proactive moderation & risk profiling. </p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className={styles.valueContainer}>
          <div>
            <img src={Moderation} className={styles.valueImage} />
            <h2 className={styles.valueHeading}>Proactive <br /> Moderation</h2>
          </div>
          <p className={styles.valueText}>We vigilantly monitor inappropriate content facilitating meaningful connections free from harmful content.</p>
        </div>
      </div> */}
      {/* <div data-aos="fade-up" data-aos-duration="1000" className={styles.sectionContainer}>
        <p>This report includes data on the total number of user reports we received, as well as the enforcement actions we have taken in response to those reports. We believe that this information is important for our stakeholders to know, so that they can feel confident that platform safety at FRND is viewed as a fundamental responsibility.</p>
      </div> */}
      <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Safe Platform Assurance - HIVE AI Model for Enhanced Video Moderation"}>
          <p>Our commitment to safety is reinforced by the implementation of a third-party AI moderation tool, ensuring a secure environment for all users. The efficacy of this approach is evident in the statistics, which demonstrate that our platform is actively monitored and protected.</p>
          <a className={styles.thirdPartyLink} rel="noreferrer" target='_blank' href='https://hivemoderation.com/visual-moderation'>Check out HIVE’s AI Moderation Model</a>
          <p><b>Surface Area:</b> The third-party AI Video moderation tool gives us 100% coverage over all the video calls hosted on our platform 24 x 7.</p>
          <p>Here’s the detailed numbers across Male and Female users:</p>
          <div className={styles.accordianContent}>
            <div className={styles.chartContainer} onClick={() => {
              handleModal(
                <Bar style={{minWidth: 500, height: 400}} height={400} options={getBarOptions('% Female Users who adhered to video content guidelines')} data={creatorsVideoObscenityOptions(data, true)} />)
            }}>
              <Bar options={getBarOptions('% Female Users who adhered to video content guidelines')} data={creatorsVideoObscenityOptions(data)} />
            </div>
            <div className={styles.chartContainer} onClick={() => {
              handleModal(
                <Bar style={{minWidth: 500, height: 400}} height={400} options={getBarOptions('% Male Users who adhered to video content guidelines')} data={usersVideoObscenityOptions(data, true)} />)
            }}>

              <Bar options={getBarOptions('% Male Users who adhered to video content guidelines')} data={usersVideoObscenityOptions(data)} />
            </div>

          </div>
          {/*<p>A remarkable 64% of users reported by creators for policy violations were proactively identified by HIVE AI.</p>*/}
        </Accordian>
      </div>
      {/* <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Active Reporting - Proactively Identifying high risk profiles"}>
          <p>The percentage of active reporters comparison between Jan 2024 and Dec 2023 are as follows:</p>
          <div className={styles.accordianContent}>
            <CanvasJSChart containerProps={{ width: '50%' }} options={activeReportersOptions} />
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1
            }}>
              <h1 style={{
                color: '#FF194C',
                fontSize: 36,
                fontWeight: 800,
                margin: 0
              }}>16%</h1>
              <p>Increase in Active Reporters</p>
            </div>
          </div>
          <p>We have successfully identified 43% of these high-risk profiles, proactively, even prior to any creator complaint being raised.</p>
        </Accordian>
      </div> */}
      <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Strong Policy Enforcement & Behavioural Rehabilitation:"}>
          <p>Creators and Users who breach FRNDs Terms of Service or Acceptable Use Policy are:
            <b>de-platformed or disincentivized</b> depending on the severity of their actions.
            Here are the details of the accounts who were either deplatformed or had their incentives reduced
          </p>
          <div><b>Female Users:</b> </div>
          <div className={styles.accordianContent}>
            <div className={styles.chartContainer} onClick={() => {
              handleModal(
                <Bar style={{minWidth: 500, height: 400}} height={400} options={getBarOptions('% of flagged female users refrained from policy violations post disincentivization', true)} data={flaggedCreatorData(data, true)} />)
            }}>

              <Bar options={getBarOptions('% of flagged female users refrained from policy violations post disincentivization', true)} data={flaggedCreatorData(data)} />
            </div>
            <div className={styles.chartContainer}>
              <Pie options={pieChartOptions(`Actions taken : ${Object.keys(data.CREATOR_ACCOUNT_DEPLATFORMED)[0]}`)} data={creatorsPieChartData(data)} />
            </div>
          </div>
          <div><b>Male Users:</b> </div>
          <div className={styles.accordianContent}>
            <div className={styles.chartContainer} onClick={() => {
              handleModal(
                <Bar style={{minWidth: 500, height: 400}} height={400} options={getBarOptions('% of flagged male users refrained from policy violations post disincentivization', true)} data={flaggedUserData(data, true)} />)
            }}>

              <Bar options={getBarOptions('% of flagged male users refrained from policy violations post disincentivization', true)} data={flaggedUserData(data)} />
            </div>
            <div className={styles.chartContainer}>
              <Pie options={pieChartOptions(`Actions taken : ${Object.keys(data.USER_ACCOUNT_DEPLATFORMED)[0]}`)} data={usersPieChartData(data)} />
            </div>
          </div>
          <p>Following the disincentivization, we saw a major behavioral improvement amongst a majority of both female and male users, following which they were re-introduced to the incentives offered by the platform.</p>
        </Accordian>
      </div>
      {/* <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Virtually Spotless Interaction Experience"}>
        <p>FRND’s internal moderation team has been able to <b>successfully audit</b> the creators which contributed to <b>71% of the Revenue</b> in the month of March 2024 </p>
          <p>The percentage of <b>Healthy streaming minutes</b> saw a remarkable surge up to <b>97.56%</b> in the month of <b>March 2024.</b></p>
          <div className={styles.fullWidthChartContainer}>
            <Bar options={getBarOptions('% Healthy Streaming Minutes')} data={healthyStreamingMinutesOptions} />
          </div>
        </Accordian>
      </div> */}
      <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Enforcement and Moderation Commitment"}>
          {/* <p>In line with our unwavering commitment to maintaining a safe and respectful online community, we enforce a stringent action policy aimed at addressing violations swiftly and effectively. As part of this policy, we have implemented a <b>One-day Turnaround Time (TAT)</b> for addressing reports, ensuring that appropriate actions are taken promptly to uphold our community standards and foster a positive user experience.</p>
          <p>As a testament to our steadfast commitment to fostering a secure and welcoming online environment, we allocate <b>1% of our revenue towards comprehensive moderation efforts</b> aimed at safeguarding the well-being of our users. This significant investment underscores our dedication to proactive measures</p> */}
          <div className={styles.accordianContent}>

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1
            }}>
              <div style={{ display: 'flex' }}>

                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <h1 style={{
                    color: '#FF194C',
                    fontSize: 36,
                    fontWeight: 800,
                    margin: 0
                  }}>{"<1 day TAT"}</h1>
                  <h3>for addressing reports and violations</h3>
                  <p style={{
                    textAlign: 'center'
                  }}>*TAT = Turn Around Time</p>
                </div>
              </div>
            </div>
            <div className={styles.chartContainer}>

              <Pie options={pieChartOptions()} data={tatPieChartData(data)} />
            </div>
          </div>
          <p>As a testament to our steadfast commitment to fostering a secure and welcoming online environment, we allocate <b>1% of our revenue towards comprehensive moderation efforts</b> aimed at safeguarding the well-being of our users.</p>
        </Accordian>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" className={`${styles.sectionContainer} ${styles.accordiansContainer}`}>
        <Accordian heading={"Glossary"}>
          <ul>
            <li>Audio-Video Calls: The one on one conversations between creators and users over audio or video medium on FRND App. </li>
            <li>Streaming Minutes: To total amount of active online time that all creators spend on FRND App.</li>
            <li>Audio/Video Obscenity: This refers to the practices including but not limited to nudity, vulgarity, hate speech, sexual innuendos, abuse, violence etc. </li>
            <li>Verification Process: The process of voice authentication employed to correctly identify the gender of a person to prevent fraudulent accounts, solicitation or extortion.</li>
            <li>Reporting: This is the process of a creator/user reporting another creator/user for the purpose of nudity, vulgarity, hate speech, sexual innuendos, abuse, violence etc. </li>
            <li>Creator Auditing: This is the process where FRND Moderation team visits creators’ rooms as a normal user to perform an authenticity check of the creator. </li>
            <li>Disincentivization: To reduce or eliminate, temporarily or with permanent effect, the incentives that a creator earns through FRND App, to disincentive any form of platform abuse. </li>
            <li>Deplatforming: This is the process of permanently removing the creator/user from being able to use the FRND App.</li>
          </ul>
        </Accordian>
      </div>
    </div>
  )
}