
import { Link, useNavigate } from 'react-router-dom';
import styles from './homepage.module.css';
import cta from './cta.svg';
import { COMMUNITIES, TOPICS } from './data';
import { TileBlock } from '../../Components/Common/Tileblock';
import { Doughnut } from 'react-chartjs-2';
import { flaggedCellsData, getDoughnutOptions, getDoughnutTitlePlugin } from '../Transparency/data';
import { useEffect, useState } from 'react';
import { Loader } from '../../Components/Common/Loader';
import { getSafetyData } from '../../modules/networkCall';


export const SafetyHomePage = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const getData = async () => {
    const data = await getSafetyData();
    setData(data?.trustAndSafetyData);
  }
  useEffect(() => {
    getData();
  }, [])
  if (!data) {
    return <div className={styles.loaderContainer}><Loader /></div>;
  }
  return (
    <div className={styles.container}>
      <section className={styles.heroSection}>
        <div className={styles.heroTextContainer}>
          <h1>Safety Center</h1>
          <p>At FRND, we take a comprehensive approach to making our technologies a better place for everyone. Learn how we're working to keep you safe on our platforms.</p>
        </div>
        <div className={styles.heroImageContainer} >

          <img className={styles.heroImage} src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr18.png" />
        </div>
      </section>
      <section className={styles.transparencySectionContainer}>
        <section className={styles.heroSection}>
          <div className={styles.heroTextContainer}>
            <h1>Statistics</h1>
            <p>
              Amongst the calls handled on our platform in {data?.cellsFlagged?.monthName}, {(100 - data?.cellsFlagged?.value).toFixed(2)}%
              were flagged. This percentage continues to decrease, showcasing
              our commitment to constant improvement.</p>
            <div onClick={() => navigate("transparency")} className={styles.ctaLink}>
              <img src={cta} />
              <p>See Full Report</p>
            </div>
          </div>
          <div className={styles.heroImageContainer} >
            <Doughnut plugins={[getDoughnutTitlePlugin(data)]} options={getDoughnutOptions()} data={flaggedCellsData(data)} />

          </div>
        </section>
      </section>
      <section className={styles.tnCSection}>
        <h2 className={styles.tncTitle}>Browse by topic or community</h2>
        <h3 className={styles.browserTitle}>Topics</h3>
        <p className={styles.browserText}>Whether you're currently facing an issue or looking to be proactive about your online safety, we want to help. Discover safety resources and tools across our technologies.</p>
        <div onClick={() => navigate("topics")} className={styles.ctaLink}>
          <img src={cta} />
          <p>See all Topics</p>
        </div>
        <div className={styles.browserGrid}>
          <div className={styles.tilesContainer}>
            <TileBlock navigationPath={"topics/"}  {...TOPICS[0]} />
          </div>
          <div className={styles.tilesContainer}>
            <TileBlock navigationPath={"topics/"} orientation='horizontal' imagePosition='end' {...TOPICS[1]} />
            <TileBlock navigationPath={"topics/"} orientation='horizontal' {...TOPICS[2]} />

          </div>
        </div>
        <h3 className={styles.browserTitle}>Communities</h3>
        <p className={styles.browserText}>Our mission is to provide a social discovery platform for making audio and video calls with friends. But none of this is possible if people don’t feel safe on our platform. Our approach to safety includes writing clear policies about what is and isn’t allowed on our platforms, developing sophisticated technology to detect and prevent abuse from happening in the first place, and providing helpful tools and resources for people to control their experience or get help.</p>
        <div onClick={() => navigate("communities")} className={styles.ctaLink}>
          <img src={cta} />
          <p>See all communities</p>
        </div>
        <div className={styles.browserGrid}>
          <div className={styles.tilesContainer}>
            <TileBlock navigationPath={"communities/"} orientation='horizontal' {...COMMUNITIES[0]} />
            <TileBlock navigationPath={"communities/"} orientation='horizontal' imagePosition='end' {...COMMUNITIES[1]} />
          </div>
          <div className={styles.tilesContainer}>
            <TileBlock navigationPath={"communities/"} {...COMMUNITIES[2]} />
          </div>
        </div>
      </section>

    </div>
  )
}