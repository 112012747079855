import { LawEnforcement } from "./Communities/articles/LawEnforcement"
import { Women } from "./Communities/articles/Women"
import { YouthSafety } from "./Communities/articles/YouthSafety"
import { ModerationPartner } from "./Resources/articles/ModerationPartner"
import { Anonimity } from "./Topics/articles/Anonimity"
import { ChildProtection } from "./Topics/articles/ChildProtection"
import { MediaSafetyGuidelines } from "./Topics/articles/MediaSafetyGuideline"
import { SafetyToolsAndPolicies } from "./Topics/articles/SafetyToolsandPolicies"

export const TOPICS = [
  {
    title: 'Safety Tools and Policies',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr1.png',
    slug: 'safety-tools-policies',
    description: 'Safety is a conversation and a responsibility we all share. Get to know how FRND approaches safety and learn about the tools and resources available to you',
    component: SafetyToolsAndPolicies
  },
  {
    title: 'Voice & Video Safety Guidelines',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr6.png',
    slug: 'media-safety-guidelines',
    description: 'At FRND, ensuring safety is our top priority. Explore our Voice & Video Safety Guidelines for expert-backed resources and tools to foster a respectful environment',
    component: MediaSafetyGuidelines
  },
  {
    title: 'Anonymity',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr16.png',
    slug: 'anonymity',
    description: 'Protecting your privacy is key at FRND. Explore our profile anonymity options for safe connections',
    component: Anonimity
  },
]


export const COMMUNITIES = [
  {
    title: 'Women',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr9.png',
    slug: 'women',
    description: 'Learn about FRND\'s comprehensive approach to making our platforms a safe place for women',
    component: Women
  },
  {
    title: 'Youth Safety',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr11.png',
    slug: 'youth-safety',
    description: 'Learn about FRND\'s tools and resources to educate and empower youth to stay safe, prioritize their well-being and create positive change',
    component: YouthSafety
  },
  {
    title: 'Child Protection',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr4.png',
    slug: 'child-protection',
    component: ChildProtection,
    description: 'Learn about FRND’s approach to child safety across our technologies, including our efforts to fight the online exploitation of children'
  },

]
export const RESOURCES = [
  {
    title: 'Moderation Partner',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr15.png',
    slug: 'moderation-partner',
    description: 'FRND’s commitment to safety is reinforced by the implementation of a third-party AI moderation tool, ensuring a secure environment for all users',
    component: ModerationPartner
  },
  {
    title: 'Law Enforcement',
    imageLink: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr13.png',
    slug: 'law-enforcement',
    description: 'FRND is committed to promoting safety in the online world.',
    component: LawEnforcement
  },
]