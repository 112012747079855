import { useNavigate } from "react-router-dom";
import { deleteCookie } from "../modules/cookie";
import { googleAppLink, googleAppStoreTrackedLink } from "./constants";
import { Helmet } from 'react-helmet'
export const openApp = () => {
    window.open(googleAppLink);
}

export const openTrackedPlayStoreLink = () => {
    window.open(googleAppStoreTrackedLink);
}

export const logOut = (path) => {
    deleteCookie("user");
    deleteCookie("auth_token");
    deleteCookie("access_token");
    if (window.location.pathname === '/') {
        window.location.reload();
    } else {
        window.location.pathname = path || '/'
    }

}

export const HomePageMetaPixel = () => {
    return <Helmet>
        <script key='function'>
            {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '274535495035249');
fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
src="<https://www.facebook.com/tr?id=274535495035249&ev=PageView&noscript=1>"
/>`}</noscript>
    </Helmet>
}
export const StarTrainerMetaPixel = () => {
    return <Helmet>
        <script key='function'>
            {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '654534039831060');
fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
src="<https://www.facebook.com/tr?id=654534039831060&ev=PageView&noscript=1>"
/>`}</noscript>
    </Helmet>
}

export function iOS() {
    // alert((navigator.userAgent))
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || 
    // iPad on iOS 13 detection
    navigator.userAgent.includes("iPhone")
  }