import { useState } from 'react';
import './carousel.css';

const CarouselPoints = ({ itemsCount, onCarouselChange }) => {

    const [carouselIndex, setCarouselIndex] = useState(0);

    const carouselHandler = (index) => {
        setCarouselIndex(index);
        onCarouselChange(index);
    }

    return (<div className='dot-container'>
        {Array.from({ length : itemsCount }).map( (item, index) => (
            <div onClick={() => carouselHandler(index)} className={`dot ${index === carouselIndex ? 'active' :'' }`}></div>
        ))}
    </div>)
};

export default CarouselPoints;