import { openApp, openTrackedPlayStoreLink } from '../../../common/utils';
import frndWhite from '../../../images/frnd_white.png';
import GoogleBadge from '../../../images/google_badge.png';
import './header.css'

const Header = () => {
    return (<div className={'profile-header'}>
        <div className={`profile-header-items`}>
            <img height={30} src={frndWhite} />
            <div onClick={openTrackedPlayStoreLink} className="cursor-pointer" style={{ marginLeft: 12 }}>
                <img width="70" src={GoogleBadge} />
            </div>
        </div>
        <div className={'profile-header-text'}>    
            <div onClick={openTrackedPlayStoreLink} className="cursor-pointer" style={{ marginLeft: 12 }}>
                Download Now
            </div>
        </div>
    </div>);
}

export default Header;