import React, { useState } from 'react';
import './Accordion.css';

export const Accordion = ({ question, answer, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div data-aos="fade-up" data-aos-duration="1000" className="Accordion">
      <div className="Accordion-header" onClick={handleToggle}>
        <div className="Accordion-header-question">{question}</div>
        <div className={`Accordion-header-icon ${isExpanded ? 'Accordion-header-icon--expanded' : ''}`}>{isExpanded ? '-' : '+'}</div>
      </div>
      <div className={`Accordion-answer ${isExpanded ? 'Accordion-answer--expanded' : ''}`}>
        {answer}
      {children}
      </div>
    </div>
  );
};

