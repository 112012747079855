import styles from '../../Topics/articles/styles.module.css';
import back from '../../back.png';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { RESOURCES } from '../../data';

export const ArticleContainer = () => {

  let { slug } = useParams();
  const navigate = useNavigate()

  const resource = RESOURCES.find((resource) => resource.slug === slug);

  if (!resource) {
    return <div className={styles.notFound}>404 Not Found</div>
  }
  const Component = resource.component

  return <div className={styles.container}>
    <div onClick={() => navigate("/safety/resources")} className={styles.backContainer}>
      <img src={back} alt='backIcon' className={styles.backIcon} />
      <h3 className={styles.backText} >Back to Resources</h3>
    </div>
    <section className={styles.heroContainer}>
      <img src={resource.imageLink} alt='backIcon' className={styles.heroImage} />
      <div className={styles.heroTextContainer}>
        <h1 className={styles.heroTitle}>
          {resource.title}
        </h1>
        <p className={styles.heroDescription}>{resource.description}</p>
      </div>
    </section>
    {Component && <Component />}
  </div>

}