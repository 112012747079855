import { useLocation } from "react-router-dom";
import Footer from "../Home/Footer";
import { BenefitsComponent } from "./Benefits";
import { Motto } from "./WhoShouldApply";
import { FAQsComponent } from "./FAQs";
import { HeroComponent } from "./Hero";
import { useEffect } from "react";
import Header from "../Common/Header";
import { FounderQuote } from "./FounderQuote";
import { VideosGrid } from "./VideosGrid";
import { Culture } from "./Culture";
import { Carousel } from "./Carousel";


const APMProgram = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {/* <StarTrainerMetaPixel /> */}
      <Header forceActive />
      <HeroComponent />
      <FounderQuote />
      <BenefitsComponent />
      <Motto />
      <Carousel />
      <Culture />
      <VideosGrid />
      <FAQsComponent />
      <Footer />
    </>
  )
}

export default APMProgram;