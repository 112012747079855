import styles from './styles.module.css';
export const SafetyToolsAndPolicies = () => {
  return <section className={styles.sectionContainer}>
    <h2>Overview</h2>
    <p>You're in charge. At FRND, we give users control over whom they want to connect with. Taking full advantage of our safety tools offers you an enriched experience.</p>
    <p>Our goals for developing safety tools are simple. We want you to:</p>
    <ul>
      <li>Have authentic interactions with genuine users.</li>
      <li>Keep your identity secure & anonymous.</li>
      <li>Be a part of the safest online community.</li>
    </ul>
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr2.png" alt="safety-heading" />
    <h3>Secure your identity</h3>
    <p>Safeguard your privacy by keeping personal details like phone numbers and addresses confidential. Be selective about who you connect with, carefully considering who you follow and who follows you.</p>
    <h3>Maintain positive interactions</h3>
    <p>Encourage constructive interactions by utilizing tools that promote healthy engagement among users. Take advantage of reporting features to swiftly address any concerns that may arise, ensuring a positive and safe environment for all.</p>
    <h3>Report abuse </h3>
    
    <p>If you encounter any user who is harassing or engaging in inappropriate behavior towards you on the platform, you can promptly report them either from their profile page or directly from the call you're in.</p>
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr19.jpg" alt="safety-heading" />
    <h3>Handling Reported Accounts</h3>
    <p>Upon receiving a report on a user, our automated tools are swiftly followed by human moderators who thoroughly investigate the issue. Prompt action will be taken, which may result in temporary or permanent account suspension or reduction of some incentives, depending on the severity of the violation.</p>
    <h3>Scam prevention</h3>
    <p>If you suspect any user of potential scam or fraud activities on the platform, please report them immediately. Your positive actions contributes to the safety of all users and helps maintain a secure platform for everyone.</p>

  </section>
}