import { useRef, useState } from "react";
import styles from './accordian.module.css';
import CaretDown from './caretDown.webp'

export const Accordian = ({ heading, children, forceOpen = false }) => {
  const [isOpen, setIsOpen] = useState(forceOpen);
  const contentRef = useRef(null)


  return (
    <div className={styles.container}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.headingContainer}>
        <h1 className={styles.heading}>{heading}</h1>
       {!forceOpen && <img alt="caret-icon" className={`${styles.caretIcon} ${isOpen ? styles.caretIconInverted: styles.caretIconNormal}`} src={CaretDown} />}
      </div>
      <div ref={contentRef} className={`${styles.childrenContainer}`} style={{height: forceOpen ? 'unset' : isOpen ? contentRef.current.scrollHeight: 0}}>
        {children}
      </div>
    </div>
  )

}