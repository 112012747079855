import { playstoreReviews } from "../config";
import RatingIcon from '../../../images/rating.png';
import './reviews.css'

const Reviews = () => {
    return (<div>
        <div className='profile-review-title'>Playstore Reviews</div>
        <div className='profile-reviews'>
            {playstoreReviews.map(review => (<div className='profile-review'>
                <div className='profile-review-icon'>
                    <img height={60} src={review.reviewerIcon}/>
                </div>
                <div className='profile-review-body'>
                    <div className='profile-review-rating'>
                        <img height="16" style={{ marginRight: 4 }} src={RatingIcon} />
                        <span>{review.rating}</span>
                    </div>
                    <div className='profile-review-handle'>{review.handle}</div>
                    <div className='profile-review-text'>{review.text}</div>
                </div>
            </div>))}
        </div>
    </div>)
}

export default Reviews;