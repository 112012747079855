import Header from './Header';
import Banner from './Banner';
import Reviews from './PlaystoreReviews';
import ProfileCard from './ProfileCard';
import AppPitch from './AppPitch';
import Features from './Features';
import ProfileReview from './ProfileReviews';
import MoreFrnds from './MoreFrnds';

import { useParams } from "react-router-dom";



const Profile = ({ match }) => {

    let { userId } = useParams();
    return (<div className='profile'>
        <Header />
        <Banner userId={userId}/>
        <ProfileCard userId={userId}/>
        <Features />
        <ProfileReview userId={userId}/>
        <AppPitch />
        <MoreFrnds />
        <Reviews/>
        <Header />
    </div>)
}


export default Profile;