import styles from './styles.module.css';
import back from '../../back.png';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { TOPICS } from '../../data';

export const ArticleContainer = () => {

  let { topicSlug } = useParams();
  const navigate = useNavigate()

  const topic = TOPICS.find((topic) => topic.slug === topicSlug);

  if (!topic) {
    return <div className={styles.notFound}>404 Not Found</div>
  }
  const Component = topic.component

  return <div className={styles.container}>
    <div onClick={() => navigate("/safety/topics")} className={styles.backContainer}>
      <img src={back} alt='backIcon' className={styles.backIcon} />
      <h3 className={styles.backText} >Back to Topics</h3>
    </div>
    <section className={styles.heroContainer}>
      <img src={topic.imageLink} alt='backIcon' className={styles.heroImage} />
      <div className={styles.heroTextContainer}>
        <h1 className={styles.heroTitle}>
          {topic.title}
        </h1>
        <p className={styles.heroDescription}>{topic.description}</p>
      </div>
    </section>
    {Component && <Component />}
  </div>

}