
import { OpenPositions } from '../../../common/constants';
import './join.css';
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import LocalizedStrings from 'react-localization';

function JoinUs() {
  let strings = new LocalizedStrings(LANGUAGE_DATA.HOME_CAREER);
  registerStrings(strings);
  const openLink = () => {
    window.open(OpenPositions);
  }

  return (
    <div className="talent-container" id="talent-container">
      <div className="talent-title" data-aos="fade-up" data-aos-duration="1000">{strings.heading}</div>
      <div className="talent-text" data-aos="fade-up" data-aos-duration="1000">{strings.description}</div>
      <div onClick={openLink} className="open-position-button" data-aos="fade-up" data-aos-duration="1000">{strings.cta}</div>
    </div>
  );
}

export default JoinUs;