import IndiaQuotient from './india_quotient.png';
import Krafton from './krafton.png';
import Pubg from './pubg.png';
import Elevation from './elevation.png';
import './investor.css';
import { InvestorLinks } from '../../../common/constants';


function Investors () {
    
    const openInvestorPage = (investor) => {
        window.open(InvestorLinks[investor])
    }

    return (
        <div className="investors">
            <div className="investor-text">Backed by</div>
            <div className="investor-container" data-aos="fade-up" data-aos-duration="1000">
                <div onClick={() => openInvestorPage("IndiaQuotient")} className="investor"><img src={IndiaQuotient}></img></div>
                <div onClick={() => openInvestorPage("ElevationCap")} className="investor"><img src={Elevation}></img></div>
                <div onClick={() => openInvestorPage("Krafton")} className="investor"><img src={Pubg}></img></div>
                <div onClick={() => openInvestorPage("Krafton")} className="investor"><img src={Krafton}></img></div>
            </div>
        </div>
    );
}

export default Investors;