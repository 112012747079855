import LocalizedStrings from 'react-localization';
import styles from './styles.module.css'
import { NEW_LANGUAGE_DATA } from '../../../modules/localisation/newTextData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';

export const FAQComponent = () => {
  let strings = new LocalizedStrings(NEW_LANGUAGE_DATA.FAQS);
  registerStrings(strings);
  return <div className={styles.faqContainer}>
    <div className={styles.question}>{strings.q1}</div>
    <div className={styles.answer}>{strings.a1}<a target='_blank' rel="noreferrer" href="https://forms.gle/5yPn8AGYE179V2EB7" className={styles.answerLink}>form</a></div>
    <div className={styles.question}>{strings.q2}</div>
    <div className={styles.answer}>{strings.a2}<a href="https://forms.gle/5yPn8AGYE179V2EB7" rel="noreferrer" target='_blank' className={styles.answerLink}>form</a></div>
  </div>
}