import './app_pitch.css';
import FRNDIcon from '../../../images/FRND_app_icon.png';
import AvatarIcon from '../../../images/avatar.png';
import Download from '../../../images/download.png';
import PlaystoreWhite from '../../../images/playstore_white.png';
import RatingIcon from '../../../images/rating.png';
import Guaranteed from '../../../images/guaranteed.png';
import UsersIcon from '../../../images/users.png';
import { openTrackedPlayStoreLink } from '../../../common/utils';

const AppPitch = () => {
    return <div style={{ marginTop: 64 }}>
        <div className='app-pitch-title'>Be my FRND now</div>
        <div className='app-pitch-container'>
            <img className='app-pitch-img' height={80} width={80} src={FRNDIcon} />
            <div className='app-pitch-text'>FRND: Meet cute FRNDs on Video & Voice Call</div>
            <div>
                <img className={'avatar-img'} src={AvatarIcon} />
            </div>
            <div className='app-pitch-online-text'>
                <span>28123+ FRNDs online now</span>
            </div>
            <div className='profile-section-container'>
                <div className='profile-section'>
                    <div><img className='profile-data-img' src={RatingIcon} /></div>
                    <div>4.5</div>
                    <div style={{ marginLeft: 24 }}><img className='profile-data-img' src={Download} /></div>
                    <div>5M+</div>
                </div>
                <div onClick={openTrackedPlayStoreLink} className='app-pitch-btn'>
                    <div className='app-pitch-download'>Download Now</div>
                </div>
                <div className='app-pitch-playstore'>
                    <img height={24} src={PlaystoreWhite} />
                </div>
                <div className='app-pitch-pros'>
                    <div className='app-pitch-columns'>
                        <img height={28} src={Guaranteed} />
                        <div className='app-pitch-section-text'> Safe & Secure Payment</div>
                    </div>
                    <div className='app-pitch-columns'>
                        <img height={28} src={Guaranteed} />
                        <div className='app-pitch-section-text'> Guaranteed Chat</div>
                    </div>
                    <div className='app-pitch-columns'>
                        <img height={28} src={UsersIcon} />
                        <div className='app-pitch-section-text'>50 Lacs+ Trusted Users</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AppPitch;