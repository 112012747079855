import User1 from './user1.png';
import User2 from './user2.png';
import User3 from './user3.png';
import Rating from './Rating';
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import LocalizedStrings from 'react-localization';


import './reviews.css';

function Reviews() {

  let strings = new LocalizedStrings(LANGUAGE_DATA.HOME_TESTIMONIALS);
  registerStrings(strings);
  return (
    <div className="reviews-container">
      <div className="reviews-heading">{strings.heading}</div>
      <div className="reviews-block" data-aos="fade-up" data-aos-duration="1000">
        <div className="review-section">
          <div className="review-block">
            <div className="review-rating"><Rating rating="5" /></div>
            <div className="review-text">
              {strings.t1}
            </div>
          </div>
          <div className="reviewer-image"><img height="60" width="60" src={User1} /></div>
          <div className="reviewer-name">Hope</div>
        </div>
        <div className="review-section">
          <div className="review-block">
            <div className="review-rating"><Rating rating="5" /></div>
            <div className="review-text">
              {strings.t2}
            </div>
          </div>
          <div className="reviewer-image"><img height="60" width="60" src={User2} /></div>
          <div className="reviewer-name">Rj Vijaya</div>
        </div>
        <div className="review-section">
          <div className="review-block">
            <div className="review-rating"><Rating rating="5" /></div>
            <div className="review-text">
             {strings.t3}
            </div>
          </div>
          <div className="reviewer-image"><img height="60" width="60" src={User3} /></div>
          <div className="reviewer-name">RJ Anju..😍</div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;