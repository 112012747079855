import styles from './coupon.module.css';
import offerIcon from '../../PaymentSummary/components/offer.svg'
import offerGreyIcon from '../../PaymentSummary/components/offer_grey.svg'
import invalidIcon from '../../PaymentSummary/components/invalid.svg'
import checkIcon from '../../PaymentSummary/components/check.svg'
import crossIcon from '../../PaymentSummary/components/cross.svg';
import { useEffect, useRef, useState } from 'react';
import { applyCoupon } from '../../../modules/networkCall';

export const CouponApplier = ({ style, initialCoupon = '', packageId, onApplySuccess, onClearCoupon }) => {
  const [state, setState] = useState('editing');
  const [coupon, setCoupon] = useState(initialCoupon);
  const ref = useRef();
  const clearCoupon = () => {
    setState('editing');
    onClearCoupon && onClearCoupon()
    setCoupon('')
  }
  const applyCouponHelper =async () => {
    setState('applying');
    const data = await applyCoupon({
      package_id: packageId,
      coupon_code: coupon
    });
    if(data?.is_success) {
      setState('applied')
      onApplySuccess && onApplySuccess(data);
    } else {
      setState('invalid');
    }
  }
  useEffect(() => {
    if(initialCoupon) {
      setState('applied')
    };
  },[])
  return (
    <div ref={ref} className={`${styles.container} ${(coupon.length > 0) && (state === 'invalid' ? styles.invalid : styles.valid)}`} style={style} >
      <div className={styles.left}>
        <img className={styles.offerIcon} src={(coupon.length > 0) ? offerIcon : offerGreyIcon} />
        <input onFocus={() => 
           ref.current.scrollIntoView()
        } disabled={state!=='editing'} value={coupon} onChange={(e) => setCoupon(e.target.value)} placeholder='Enter Coupon Code' className={styles.couponInput} />
      </div>
      {state === 'editing' && (coupon.length > 0) && <div onClick={applyCouponHelper} className={styles.applyButton}>Apply</div>}
      {state === 'applying' && <div className={styles.appliedTextContainer}>
        <div>Validating...</div>
      </div>}
      {state === 'applied' && <div className={styles.appliedTextContainer}>
        <img className={styles.icon} src={checkIcon} />
        <div>Applied</div>
      </div>}
      {state === 'invalid' && <div className={styles.invalidTextContainer}>
        <img className={styles.icon} src={invalidIcon} />
        <div>Invalid</div>
      </div>}
      {
        (state === 'applied' || state === 'invalid' )&& <img onClick={clearCoupon} className={styles.clearIcon} src={crossIcon} />
      }
    </div>
  )
}