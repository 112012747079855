import './footer.css';
import frndWhite from '../../../images/frnd_white.png';
import Instagram from './instagram.png';
import linkedin from './linkedin.svg';
import { Button } from '../../Common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate()
  const navigateToDownloadApk = () => {
    navigate("/download-app");
  }

  const scrollElement = (section) => {
    switch (section) {
      case "top":
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return;
      case "features":
       window.location.href="/#features-container"
        return;
      case "careers":
        window.location.href="/careers"
        return;
      case "privacy_policy":
        window.open("/privacy");
        return;
      case "safety":
        window.open("/safety");
        return;
    }
  }

  return (
    <div className="super-footer-container">

          <div onClick={() => scrollElement("top")}><img width="100" src={frndWhite} /></div>
      <div id="footer-container" className='footer-container'>
        <div>
          <div className="nav-list">
            <div onClick={() => scrollElement("top")} className="nav-list-item">Home</div>
            <div onClick={() => scrollElement("features")} className="nav-list-item">Features</div>
            <div onClick={() => scrollElement("careers")} className="nav-list-item">Careers</div>
            <div onClick={() => scrollElement("safety")} className="nav-list-item">Safety Center</div>
                <Link onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }} to="/privacy" className="nav-list-item">Privacy Policy</Link>
            <Link onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }} to="/terms-and-conditions" className="nav-list-item">Terms & Conditions</Link>
          </div>
          <div>

            <a href='mailto:careers@frnd.app'>careers@frnd.app</a>
          </div>
          <a href='https://www.linkedin.com/in/tanisha-g-897477167/'>HR - <u>Tanisha Gagneja</u></a>
        </div>
        <div className="address">
          <div className="social-icons">
            <a href="https://www.instagram.com/frndapp?igsh=cHFkcGJ2bTM4dTJz"><img href="" src={Instagram} /></a>
            <a href="https://www.linkedin.com/company/frnd-app/"><img href="" src={linkedin} /></a>
          </div>
          <div>UrbanVault Site No. 1781,</div>
          <div>19th Main Rd, Vanganahalli,</div>
          <div>1st Sector, HSR Layout,</div>
          <div>Bengaluru,</div>
          <div>Bengaluru Urban, Karnataka,</div>
          <div>560102</div>
        </div>
      </div>
      <div className="company-title">© 2024 Cold Brew Tech Pvt. Ltd. All rights reserved</div>
    </div>
  );
}

export default Footer;