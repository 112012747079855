import styles from './titleDivider.module.css';

export const TitleDivider = ({ heading, subheading }) => {
  return <div className={styles.container}>
    <div className={styles.divider} />
    <div>

      <h2 className={styles.heading}>{heading}</h2>
      <h2 className={styles.subheading}>{subheading}</h2>
    </div>
    <div className={styles.divider} />
  </div>
}