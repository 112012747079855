import styles from './benefits.module.css'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import LocalizedStrings from 'react-localization';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import { SlantHeading } from '../components/SlantHeading/index';

export const BenefitsComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_JOIN_US_BENEFITS);
  registerStrings(strings);
  const BENIFITS_DATA = [
    {
      title: 'Founder’s Office Exposure 🤩',
      description: 'This will give you first hand exposure to high-impact decision making, product strategy, and leadership, enabling you to directly contribute to shaping our vision.'
    },
    {
      title: 'Ownership & Mentorship 💪🏼',
      description: 'We’ve got full faith in you. You’ll have the freedom to own projects and make a real impact. Don’t worry, our product mentors will always have your back!'
    },
    {
      title: 'APM in 6 months 🎉',
      description: 'Vibe and thrive for 6 months; and- congratulations. You\'ve just unlocked the "Real APM" level, think of it as a fast track to the product world!'
    }
  ]

  return (
    <div className={styles.container}>
      <SlantHeading prefix={"Why"} heading={"FRND’s APM Program?"} />
      <div className={styles.benifitsContainer}>
        <div className={styles.benifitsContent}>
          <p className={styles.subHeading} data-aos="fade-up" data-aos-duration="1000">
            ( aka all the cool things you’ll experience 😬 )
          </p>
          <div className='benefits-container'>
            {
              BENIFITS_DATA.map((benifit, key) => {
                return <div key={key.toString()} data-aos="fade-up" data-aos-duration="1000" className='benefit-container'>
                  <div className='benefit-text'>
                    <h3 style={{fontSize: 16}}>{benifit.title}</h3>
                    <p style={{ color: 'rgba(48, 48, 48, 1)' }}>{benifit.description}</p>
                  </div>
                </div>
              })
            }
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img data-aos="zoom-in" data-aos-duration="1000" className={styles.benefitsImage + ' benefits-image'} src={"https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/DanishAditya1.png"} />
        </div>

      </div>
    </div>
  )
}