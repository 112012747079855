import styles from './founder.module.css';

export const FounderQuote = () => {
  return (
    <div className={styles.container}>
      <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Harshfoundersection1.png" alt="founder" />
      <div className={styles.textContainer}>
        <h2>What our founder has to say?</h2>
        <p>“The APM Program is our way of investing in the next generation of product leaders who will shape not only the future of FRND but also the future of startup ecosystem.”</p>
        <span>- Harsh, Founder & CPO, FRND</span>
      </div>
    </div>
  )
}