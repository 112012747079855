/* eslint-disable no-undef */
import { useLocation } from "react-router-dom";
import Header from "../../Components/Common/Header";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../modules/cookie";

export const PaymentFrame = () => {

  const { search } = useLocation();
  const qp = new URLSearchParams(search);
  const frameSrc = qp.get('payment_url');
  useEffect(() => {
    window.onbeforeunload = function () {
      if (window?.app) {
        window?.app?.closeWebView();
      }
    }
    const orderSource = getCookie('order_source');
    if (!orderSource) {
      setCookie('order_source', 'app')
    }
  }, [])
  return (
    <div style={{
      paddingTop: 72
    }}>
      <Header forceActive />
      {window.innerWidth < 600 && <div style={{
        padding: '18px 0', textAlign: 'center', fontSize: 18,
        color: '#FFFFFF',
        background: '#0891B2', fontWeight: 700,
        position: 'relative'
      }}>Payment Methods</div>}
      {frameSrc && <iframe title="payment-screen" allow="payment *;" src={frameSrc} style={{
        borderStyle: "none",
        width: "100%",
        height: window.innerWidth < 600 ? "calc(100vh - 148px)" : "calc(100vh - 90px)",
        marginTop: window.innerWidth < 600 ? -58 : 0
      }}></iframe>
      }
    </div>
  )
}