import { profileConfig } from '../config';

const Banner = ({ userId }) => {
    return <div className='profile-banner'>
        <img 
            style={{ width: '100vw' }}
            src={profileConfig.userData[userId].bannerImageUrl}
        />
    </div>
}

export default Banner;