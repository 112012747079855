import { useEffect, useMemo, useState } from 'react';
import { Button } from '../../Components/Common/Button/Button';
import { CouponApplier } from './components/CouponApplier';
import styles from './styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '../../Components/Common/Loader';
import { buyCoinPackage, getPaymentDetails } from '../../modules/networkCall';
import safety from '../PaymentSummary/safety.png'
import successIcon from '../PaymentSummary/success.svg';
import image1 from '../PaymentSummary/image1.png';
import image2 from '../PaymentSummary/image2.png';
import { CarouselDesktop } from '../../Components/Common/CorouselDesktop/CarouselDesktop';
import { Benifits } from '../Packages/components/Benifits';
import { HomePageMetaPixel } from '../../common/utils';
import { setCookie } from '../../modules/cookie';
import phonePe from './phonepe.png'
import back from '../../Components/Common/Header/back.png';
import gpay from './gpay.png';
import Coin from '../PaymentStatus/coin.png';
import { HERO_PAYMENT_VIDEO } from './constants';
const UPI_DATA_MAP = {
  phonepe: {
    icon: phonePe,
    displayName: 'PhonePe'
  },
  gpay: {
    icon: gpay,
    displayName: 'Google Pay'

  }
}

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const PaymentSummaryApp = () => {
  const timerValue = Number(process.env.REACT_APP_PAYMENT_REDIRECTION_TIME_SECS);
  const params = useQuery();
  const [order_source, coin_pkg_id, coupon, discount_pkg_id, upi_handler, auth_token, device_type, refresh_token] = [
    params.get("order_source"),
    params.get("coin_pkg_id"),
    params.get("coupon"),
    params.get("discount_pkg_id"),
    params.get("upi_handler"),
    params.get("auth_token"),
    params.get("device_type"),
    params.get("refresh_token"),
  ];
  const UPI_OPTIONS = upi_handler ? upi_handler?.split(",") : [];

  const [timer, setTimer] = useState(timerValue);
  const [timerEnabled, setTimerEnabled] = useState(true)

  const [selectedUpiOption, setSelectedUpiOption] = useState(UPI_OPTIONS[0])
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  // const [paymentData, setPaymentData] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const handleOrderPlacement = async (forceWeb = false) => {
    setIsLoading(true);
    setTimerEnabled(false);
    let apiBody = {
      payment_method: "WEB",
      coin_package_id: Number(coin_pkg_id),
      "is_app_redirect": device_type || true, // add this only while calling this api when redirect happens
      "preferred_upi": selectedUpiOption,
      device_type
    }
    if (paymentDetails.coupon_id && paymentDetails.discount_package_id) {
      apiBody = {
        ...apiBody,
        coupon_id: paymentDetails.coupon_id,
        discount_package_id: paymentDetails.discount_package_id,
      }
    }
    const data = await buyCoinPackage(apiBody);
    window.fbq('track', 'InitiateCheckout', {
      content_ids: [coin_pkg_id],
      value: paymentDetails.total_amount,
      currency: 'INR'
    });
    if (data.order_id) {
      setCookie('order_source', order_source);
      if (!forceWeb && data?.juspay?.payment_url?.default) {
        window.location.href = data?.juspay?.payment_url?.default;
        navigate("/payment/status?order_id=" + data.order_id);
        return;
      }
      if (data?.juspay?.payment_url?.web) {
        window.location.href = data?.juspay?.payment_url?.web;
      }
    }
  }
  setCookie('access_token', auth_token);
  setCookie('device_type', device_type);
  setCookie('refresh_token', refresh_token);

  const helper = async (clearCoupon) => {
    try {
      const paymentDetails = await getPaymentDetails({
        coin_package_id: Number(coin_pkg_id),
        coupon_id: clearCoupon ? undefined : Number(coupon),
        discount_package_id:clearCoupon ? undefined :  Number(discount_pkg_id),
        redirect_from: device_type
      });
      if (paymentDetails?.coins) {
        setPaymentDetails({ ...paymentDetails, original_price: paymentDetails.total_amount });
        // setPaymentData({ ...paymentDetails, original_price: paymentDetails.total_amount })
        setIsLoading(false)
      }
      setCookie('show_add_to_hs_banner', paymentDetails?.can_show_add_screen ? "show" : "hide");
    } catch (error) {
      console.log("ERROR:: INTERBAL SERVER ERROR", error)
    }
  }

  useEffect(() => {
    helper()
  }, []);

  useEffect(() => {
    let timerFn = null;
    if (timerEnabled) {
      timerFn = setInterval(() => {
        if (timer <= 0) {
          handleOrderPlacement();
          clearInterval(timerFn);
          return;
        } else {
          setTimer((timer) => timer - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerFn)

  }, [timer, timerEnabled])

  return (
    <>

      <div className={styles.desktop}>
        <HomePageMetaPixel />
        {/* <Header leftAction={() => { navigate(-1) }} leftIcon hideCrossIcon forceActive logoText="FRND Coins" /> */}
        <div className={styles.screenContainer}>

          <div className={styles.leftPane}>
            <CarouselDesktop images={[image1, image2]} />
            <Benifits textColor={'white'} />
          </div>
          <div className={styles.rightPane}>
            {isLoading ? <Loader /> : <div className={styles.container}>
              <div className={styles.heroContainer}>
                <video poster="https://i.ibb.co/4Y7LFV7/videoframe-5194-1.png" playsinline="" autoplay loop style={{ width: '100vw' }} muted autoPlay>
                  <source src={HERO_PAYMENT_VIDEO} type="video/mp4" />
                </video>
                <img src={safety} className={styles.safetyImage} />
              </div>
              <div className={`${UPI_OPTIONS.length > 0 ? styles.content : styles.contentBig}`}>
                <div className={styles.billContainer}>
                  <div className={styles.row}>
                    <div className={styles.coinsAndIconContainer}>
                      <img src={Coin} className={styles.coinsIcon} />
                      <div className={styles.coinsLabel}>{paymentDetails.coins} Coins</div>
                    </div>
                    <div className={styles.coinsValue}>₹{paymentDetails.package_price}</div>
                  </div>
                  {paymentDetails.website_discount > 0 && <div className={`${styles.row} ${styles.rowHighlight}`}>
                    <div className={styles.couponsLabel}>Website Discount</div>
                    <div className={styles.couponsValue}>-₹{paymentDetails.website_discount}</div>
                  </div>}
                  {paymentDetails.discount_amount > 0 && <div className={styles.row}>
                    <div className={styles.couponsLabel}>Coupon ({paymentDetails.coupon_code})</div>
                    <div className={styles.couponsValue}>-₹{paymentDetails.discount_amount}</div>
                  </div>}
                  <div className={styles.rowWithDivider}>
                    <div className={styles.finalLabel}>Final Amount</div>
                    <div className={styles.finalValue}>₹{paymentDetails.total_amount}</div>
                  </div>
                </div>
                <CouponApplier
                  packageId={coin_pkg_id}
                  onApplySuccess={({ discount_price, discount, discount_package_id, coupon_id, coupon_code }) => {
                    setPaymentDetails({
                      ...paymentDetails,
                      total_amount: discount_price,
                      discount_amount: discount,
                      discount_package_id,
                      coupon_id,
                      coupon_code
                    })
                  }}
                  onClearCoupon={() => {
                    helper(true);
                    // setPaymentDetails({
                    //   ...paymentDetails,
                    //   total_amount: paymentData.total_amount,
                    //   discount_amount: 0,
                    //   discount_package_id: null,
                    //   coupon_id: null,
                    //   coupon_code: null
                    // })
                  }}
                  initialCoupon={paymentDetails.coupon_code || ''}
                  style={{
                    margin: '16px 0'
                  }} />
                <Button onPress={handleOrderPlacement} shimmer className={`${styles.desktopButton} ${styles.button}`} buttonText={"Add coins now"} backgroundColor={"#1BB785"} />
              </div>
              <div className={styles.actionCenter}>
                {selectedUpiOption && <div className={styles.selectedPaymentContainer}>
                  <div className={`${styles.iconContainer}`}>
                    {showPaymentOptions ? null : <div className={`${styles.upiOption} ${styles.selectedOption}`}>
                      <img src={successIcon} className={styles.selectedIcon} />
                      <img src={UPI_DATA_MAP[selectedUpiOption].icon} className={styles.paymentIcon} />
                    </div>}
                    <div className={styles.paymentOptionText}>{showPaymentOptions ? 'Select UPI' : UPI_DATA_MAP[selectedUpiOption].displayName}</div>
                  </div>
                  <div onClick={() => {
                    if (UPI_OPTIONS.length > 1) {
                      setShowPaymentOptions(!showPaymentOptions)
                    }
                    else {
                      handleOrderPlacement(true)
                    }
                  }} className={`${styles.iconContainer}`}>
                    {showPaymentOptions ? null : <div className={styles.morePaymentOptionText}>{UPI_OPTIONS.length > 1 ? "More UPI" : "Change Payment Mode"}</div>}
                    <img src={back} className={`${showPaymentOptions ? styles.morePaymentsIconRotate : null} ${styles.morePaymentOptionIcon}`} alt='more upi options icon' />
                  </div>
                </div>}
                <div className={`${showPaymentOptions ? null : styles.hiddenupiSelectorContainer} ${styles.upiSelectorContainer}`}>
                  <div className={styles.upiOptions}>

                    {
                      UPI_OPTIONS.map((key) => {
                        const option = UPI_DATA_MAP[key];
                        if (!option) return false;
                        return (
                          <div onClick={() => {
                            setSelectedUpiOption(key)
                            setShowPaymentOptions(false)
                          }
                          } className={styles.largePaymentOptionContainer}>

                            <div className={`${styles.upiOption} ${styles.upiOptionLarge} ${key === selectedUpiOption && styles.selectedOption}`}>
                              {key === selectedUpiOption && <img src={successIcon} className={styles.selectedIcon} />}
                              <img src={option.icon} className={styles.paymentIcon} />
                            </div>
                            <div>{option.displayName}</div>
                          </div>
                        )
                      }).filter(Boolean)
                    }
                  </div>
                  <div onClick={() => handleOrderPlacement(true)} className={styles.morePackagesButton}>Change Payment Mode</div>

                </div>
                <div className={styles.selectorContainer}>
                  <Button onPress={handleOrderPlacement} shimmer className={styles.button} buttonText={"Add coins now"} backgroundColor={"#1BB785"} />
                  {timerEnabled && <p className={styles.timerText}>Redirecting to {UPI_OPTIONS.length > 0 ? "UPI" : "Payment Screen"} <span>{timer}</span></p>}
                  <div onClick={() => navigate("/packages")} className={styles.morePackagesButton}>More Packages</div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </>
  )

}