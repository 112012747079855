import { useEffect } from "react";
import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Home/Footer";
import { deleteCookie } from "../../modules/cookie";

export const PurchaseCallback = () => {

  useEffect(() => {
    deleteCookie('order_source');
  },[])
  return(
  
  
  <>
  <div style={{padding: "302px 32px"}}>
    <Button buttonText={"Open FRND App FRNDAPP"} onPress={() => {
      try {
        window.location.href = 'frndapp://applink?key~action=show-screen&key~screen-id=reminder-vivo-match';
      } catch(e) {
      }
    }} backgroundColor={'#4AA9FF'} />
    <Button buttonText={"Open FRND App Intent"} onPress={() => {
      try {
        window.location.href = 'intent://frnd.app/#Intent;scheme=https;package=com.dating.for.all;end';
      } catch(e) {
      }
    }} backgroundColor={'#4AA9FF'} />

    {/* <div style={
      {
        height: 16,
            background: 'white'
      }
    } />
    <Button buttonText={"Ultimate Button"} onPress={() => {
      window.location = 'frndapp://webview/purchase-callback';
      setTimeout(function(){
        const x = window.confirm('Do you want to continue to FRND App?')
        if(x){
          window.location.href = 'https://frnd.app/webview/purchase-callback';
        }
      }, 300);
    }} backgroundColor={'#4AA9FF'} />

    <div style={
      {
        height: 16,
            background: 'white'
      }
    } /> */}
  </div>
  </>
)}