import React from 'react';
import styles from './styles.module.css';
import BG from "./bg.png";
import Wohoo from "./wohoo.png";
import SaveMore from "./saveMore.png";
import Website from "./website.png";
import CloseWhite from "../../../Components/Common/Header/close_white.svg";
import { PWAInstallButton } from '../../../Components/Common/PWAButton';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import lottieJson from './confettiBig.json'
import confettiSmall from './confetti.json'
import { getCookie } from '../../../modules/cookie';


export const SavingsBanner = ({ savingAmount, onClose }) => {
  const showPWAButton = getCookie('pwa_button_added') !== 'added';
  return (
    <div className={styles.container}>
      <img src={BG} alt='Background image' className={styles.backgroundImg} />
      <img onClick={onClose} src={CloseWhite} alt='Close image' className={styles.closeWhiteImg} />
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Lottie
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          animationData={confettiSmall}
          className={styles.confettiSmall}
          play
        />
        <img src={Wohoo} alt='WOhoo image' className={styles.wohooImg} />
        <Lottie
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          animationData={confettiSmall}
          className={styles.confettiSmall2}
          play
        />

      </div>
      <span className={styles.savingsContainer}>
        <p className={styles.priceText}>₹{savingAmount}</p>
        <p className={styles.savingText}>saved on</p>
      </span>
      <img src={Website} alt='Website image' className={styles.wohooImg} />
      <Lottie
        loop={false}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        animationData={lottieJson}
        className={styles.bigConfetti}
        play
      />
      {showPWAButton && <PWAInstallButton className={styles.saveMoreImg}>
        <img src={SaveMore} alt='Website image' className={styles.saveMoreImg} />
      </PWAInstallButton>}
    </div>
  )
}