import PIImage from './images/social-media-comments.png';
import CommunityImage from './images/rocket.png';
import AnonImage from './images/heart-plant.png';
import DoveImage from './images/dove.png';
import BenifitsLarge from './images/hero.png';
import styles from './benefits.module.css'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import LocalizedStrings from 'react-localization';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';

export const BenefitsComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_JOIN_US_BENEFITS);
  registerStrings(strings);
  const BENIFITS_DATA = [
    {
      icon: PIImage,
      title: strings.b1Title,
    },
    {
      icon: CommunityImage,
      title: strings.b2Title,
      descriptions: [strings.b2Text1, strings.b2Text2],
    },
    {
      icon: AnonImage,
      title: strings.b3Title,
    },
    {
      icon: DoveImage,
      title: strings.b4Title,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.benifitsContent}>
        <h1 data-aos="fade-up" data-aos-duration="1000">
          {strings.heading}
        </h1>
        <p className={styles.subHeading} data-aos="fade-up" data-aos-duration="1000">
          {strings.subHeading}
        </p>
        <div className='benefits-container'>
          {
            BENIFITS_DATA.map((benifit, key) => {
              return <div key={key.toString()} data-aos="fade-up" data-aos-duration="1000" className='benefit-container'>
                <img className='benefit-icon' src={benifit.icon} />
                <div className='benefit-text'>
                  <h3>{benifit.title}</h3>
                  {benifit.descriptions?.map((desc) => <p style={{ fontSize: 16 }}>{desc}</p>)}
                </div>
              </div>
            })
          }
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img data-aos="zoom-in" data-aos-duration="1000" className={styles.benefitsImage + ' benefits-image'} src={BenifitsLarge} />
      </div>

    </div>
  )
}