import { profileConfig } from '../config';
import CarouselPoints from '../../Common/Carousel';
import { useRef } from 'react';
import './profile_review.css';

const ProfileReview = ({ userId }) => {
    
    const userReviews = profileConfig.userData[userId].reviews;

    const slideEl = useRef(document.createElement("div"));

    const carouselHandler = (index) => {
        const width = document.getElementsByClassName('carousel-item')[0].offsetWidth;
        slideEl.current.style.transform = `translateX(-${index * width}px)`;
    }
    return (<div>
        <div className='profile-review-title'>My Reviews</div>
        <div className='profile-review-container'>
            <div className='profile-review-carousel' ref={slideEl}>
                {userReviews.map((review) => ( 
                    <div className='profile-review-section'>
                        <div className='profile-review-comment'>{review.reviewText}</div>
                        <div>
                            <img height={48} width={48} src={review.reviewerImage} />
                        </div>
                        <div className={'profile-review-name'}>{review.reviewerName}</div>
                    </div>
                ))}
            </div>
        </div>
        <CarouselPoints onCarouselChange={carouselHandler} itemsCount={userReviews.length} />
    </div>)
};

export default ProfileReview;