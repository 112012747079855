import { SlantHeading } from '../components/SlantHeading';
import styles from './culture.module.css';

export const Culture = () => {
  return (
    <div className={styles.superContainer}>
      <SlantHeading prefix={"Life"} heading={"& Culture at FRND"} />
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h2>Unique Problem Statements 🚀</h2>
          <p>At FRND, we're passionate about tackling challenges that go beyond the ordinary, whether it’s ensuring women safety, and comfort on our platform, designing an interface that works seamlessly for Tier 2 and Tier 3 users or gamifying socialising to help people create lasting FRNDships.</p>
        </div>
        <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/Teamatfrnd1.png" alt="founder" />
      </div>
    </div > 
  ) 
}