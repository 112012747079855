import { useState, useEffect, useRef } from 'react';
import LanguageIcon from './icon/Language.png'
import Check from './icon/check.png'
import Caret from './icon/caret.png'
import './LanguageDropdown.css';
import { setCookie } from '../../../modules/cookie';
import { getCookie } from '../../../modules/cookie';
import { LANGUAGES_DATA, applyLanguage, getCodeFromLanguage } from '../../../modules/localisation/LocalisationModule';

function LanguageDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const dropdownRef = useRef(null);

 

  useEffect(() => {
    const lang = getCookie('lang') || LANGUAGES_DATA[0].label;
    handleLanguageSelect(lang)
  },[])

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (language, refresh) => {
    setCookie('lang',language);
    setSelectedLanguage(language);
    if(refresh) (window.location = (window.location.href.split("#").join("")).split("?")[0]);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="language-dropdown" ref={dropdownRef}>
      <div className="language-dropdown-toggle" onClick={toggleDropdown}>
        <img className='lang-img' src={LanguageIcon} alt="Language Icon" width="16" height="16" />
        <span className="selected-language">{'Language'}</span>
        <img src={Caret} className={`caret ${isOpen ? 'caret-up' : 'caret-down'}`} />
      </div>
      {isOpen && (
        <ul className="language-dropdown-menu" style={{opacity : isOpen ? 1: 0}}>
          <li className='no-select'>
            <img src={Caret} className={`caret-left`} onClick={toggleDropdown} />
            <b>Choose a language</b>
            </li>
          {LANGUAGES_DATA.map((language) => (
            <li className='list-item' style={{fontWeight: selectedLanguage === language.label ? 'bold' : 'normal'}} key={language.value} onClick={() => handleLanguageSelect(language.label, true)}>
              {language.label}
              {selectedLanguage === language.label && <img src={Check} />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LanguageDropdown;
