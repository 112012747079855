import { useState } from "react"

export const LinkTester = () => {
  const [input, setInput] = useState("");
  const ontest = () => {
    window.location.href = input;
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }} >
      <h1>Link Tester FRND</h1>
      <p style={{
        textAlign: 'center',
        margin: 16      }}>Paste any link in this input and hit "TEST". It will try to navigate to that link</p>
      <input style={{
        width: "90%",
        padding: 16,
        marginBottom: 32,
        borderRadius: 8

      }} placeholder="Enter Test URL" type="url" onChange={({ target }) => {
        setInput(target.value)
      }
      } />

      <div style={{
        width: "90%",
        padding: 16,
        background: "#ffaaff",
        borderRadius: 8,
        textAlign: 'center'

      }} onClick={ontest}>Test</div>
    </div>
  )
}