import styles from './styles.module.css';
export const ChildProtection = () => {
  return <section className={styles.sectionContainer}>
    <h2>Overview</h2>
    <p>We focus on preventing harm by enforcing zero-tolerance policies and preventative tools.</p>
   
    <h3>Building a Child Protection Ecosystem</h3>
    <p>Primarily, we enforce strict regulations making sure that individuals under the age of 18 have very limited access to the application. We take reports seriously and promptly take stringent actions against the offenders. </p>
    <p>Furthermore, we are actively working on enhancing our child protection framework to ensure a more sophisticated and comprehensive safeguarding system.</p>
    <img src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/safetycntr5.png" alt="safety-heading" />
    
  </section>
}