import HeroImage from './images/hero_group.png';
import CelebHeroText from './images/celebHeroText.png';
import styles from './hero.module.css';
import { registerStrings } from '../../../modules/localisation/LocalisationModule'
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { useState } from 'react'

export const HeroComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_JOIN_US_HERO);
  let strings2 = new LocalizedStrings(LANGUAGE_DATA.CELEB_JOIN_US_FORM);
  registerStrings(strings2);
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    gender: 'F',
    referredBy: '',
    socialLink: '',
    subscribersCount: ''
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const validateMobileNumber = () => {
    const mobileNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    return mobileNumberRegex.test(formData.mobileNumber);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!formData.name || !formData.gender || !formData.mobileNumber || !formData.referredBy || !formData.language || !formData.subscribersCount || !formData.socialLink) {
      setMessage("*All the fields are mandatory")
      return;
    } else if(!validateMobileNumber(formData.mobileNumber)) {
      setMessage("*Please enter a valid mobile number")
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "OAuth 952");
    var formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("mobile_no", formData.mobileNumber);
    formdata.append("referred_by", formData.referredBy);
    formdata.append("gender", formData.gender);
    formdata.append("language", formData.language);
    formdata.append("socialLink", formData.socialLink);
    formdata.append("subscribersCount", formData.subscribersCount);
    formdata.append("type", "Celebrity");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: formdata
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL
    fetch(`${BASE_URL}/joinUs`, requestOptions).then((res) => res.json())
    .then((data) => {
      if(data.error) {
        setMessage(data.error.err_message)
      } else {
        // setMessage(data.message);
        window.alert("Your registration was successful! Our team will reach out to you in 24 hours")
        setFormData({
          name: '',
          mobileNumber: '',
          gender: 'F',
          referredBy: '',
          subscribersCount: '',
          socialLink: ''
        })
      }
    })
    .finally(() => {
      window.fbq('track', 'SubmitApplication');
    })

  };
  registerStrings(strings);
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroContent}>
        <span><h1 data-aos="fade-up" data-aos-duration="1000" className={styles.heroHeading}>Become a <img  width={180} src={CelebHeroText}></img> on FRND App!</h1></span>
        <p data-aos="fade-up" data-aos-duration="1000" className={styles.heroSubheading}>{strings.subheading}</p>


        <form data-aos="fade-up" data-aos-duration="1000" onSubmit={handleSubmit}>
          <div className={styles.formTextFieldsContainer}>
            <input
              type="text"
              id="name"
              className={styles.formTextField}
              name="name"
              placeholder={strings2.name}
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="text"
              id="mobileNumber"
              className={styles.formTextField}
              maxLength={10}
              name="mobileNumber"
              placeholder={strings2.number}
              value={formData.mobileNumber}
              onChange={handleChange}
            />
         
          </div>

          <div className={styles.formTextFieldsContainer}>
            <div className={styles.languageSelector}>

            
          <select
              id="language"
              name="language"
              value={formData.language}
              onChange={handleChange}
            >
              <option value="" disabled selected>*Select your language</option>
              <option value="Hindi">Hindi</option>
              <option value="Tamil">Tamil</option>
              <option value="Telugu">Telugu</option>
              <option value="Odia">Odia</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Kannada">Kannada</option>
              <option value="Malayalam">Malayalam</option>
              <option value="Other">Other</option>
            </select>
            </div>
            <input
              type="text"
              id="referredBy"
              place
              className={styles.formTextField}
              name="referredBy"
              title='how did you get to know about us?'
              placeholder={strings2.referredBy}
              value={formData.referredBy}
              onChange={handleChange}
            />
            </div>
            <div className={styles.formTextFieldsContainer}>
            <input
              type="text"
              id="socialLink"
              className={styles.formTextField}
              name="socialLink"
              placeholder={strings2.socialLink}
              value={formData.socialLink}
              onChange={handleChange}
            />
            <input
              type="text"
              id="subscribersCount"
              className={styles.formTextField}
              maxLength={10}
              name="subscribersCount"
              placeholder={strings2.subscribersCount}
              value={formData.subscribersCount}
              onChange={handleChange}
            />
         
          </div>
          
            <div className={styles.formTextFieldsContainer}>

          <div>

          <div>
            <div >
              <label className={styles.radioLabel}>{strings2.gender}:</label>
            </div>
            <div className='radio-options-container'>
              <label className={styles.radioLabel}>
                {strings2.male}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="M"
                  checked={formData.gender === 'M'}
                  onChange={handleChange}
                />
              </label>
              <label className={styles.radioLabel}>
                {strings2.female}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="F"
                  checked={formData.gender === 'F'}
                  onChange={handleChange}
                />
              </label>
              <label className={styles.radioLabel}>
                {strings2.other}
                <input
                  type="radio"
                  name="gender"
                  className={styles.radio}
                  value="O"
                  checked={formData.gender === 'O'}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
          </div>
          <button className={styles.button} type="submit">{strings2.submitButton}</button>
          </div>
         
          
          <p className={styles.message}>{message}</p>
        </form>
      </div>
      <div className={styles.heroGrid}>
       <img data-aos="zoom-in" data-aos-duration="1000" className={styles.heroGridImage} src={HeroImage} />
      </div>

    </div>
  )
}