import International from './healthinsurance.png';
import Time from './flexible-time.png';
import Salary from './salary.png';
import Weekend from './weekend_fun.png';
import Leave from './leave.png';
import Library from './library.png';

import './usp.css';
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import LocalizedStrings from 'react-localization';

function USP() {

  let strings = new LocalizedStrings(LANGUAGE_DATA.HOME_CAREER);
  registerStrings(strings);
    return (
        <div className="usp-container">
            <div className="usp-title">{strings.whyUs}</div>
            <div data-aos="fade-up" data-aos-duration="1000">
                <div className="usp-points">
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={Salary} /></div>
                        <div className="usp-text"><span>{strings.salary}</span></div>
                    </div>
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={Leave} /></div>
                        <div className="usp-text"><span>{strings.leave}</span></div>
                    </div>
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={International} /></div>
                        <div className="usp-text"><span>{strings.health}</span></div>
                    </div>
                </div>
                <div className="usp-points">
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={Weekend} /></div>
                        <div className="usp-text"><span>{strings.weekend}</span></div>
                    </div>
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={Time} /></div>
                        <div className="usp-text"><span>{strings.flexibleTime}</span></div>
                    </div>
                    <div className="usp-point-container">
                        <div className="usp-img"><img height="50" width="50" src={Library} /></div>
                        <div className="usp-text"><span>{strings.library}</span></div>
                    </div>
                </div></div>
        </div>
    );
}

export default USP;