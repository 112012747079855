import { useEffect, useRef, useState } from "react";
import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import styles from './downloadApp.module.css';
import reviewsImage from './reviews.png';
import harmImage from './harm.png';
import closeIcon from './../../Components/Common/Header/close.svg';
import { Link, useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { NEW_LANGUAGE_DATA } from "../../modules/localisation/newTextData";
import { registerStrings } from "../../modules/localisation/LocalisationModule";
import { HomePageMetaPixel } from "../../common/utils";
import { getCookie, setCookie } from "../../modules/cookie";
import introVideo from '../../video.mp4'

const FIRST_DOWNLOAD_VIDEO = introVideo;
const RE_DOWNLOAD_VIDEO = introVideo;
const DownloadApp = () => {
  let strings = new LocalizedStrings(NEW_LANGUAGE_DATA.DOWNLOAD_APP);
  registerStrings(strings);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5);
  const [showVideo, setShowVideo] = useState(false);
  const [clicked, setCLicked] = useState(false)
  const videoRef = useRef(null);
  const downloadApp = () => {
    setCLicked(true);
    setTimeLeft(0);
    window.location.href = window.oneLinkParams.clickURL;
    window.fbq('trackCustom', 'DownloadApk');
    const hasUserDownloadedOnce = getCookie('appDownloaded');
    if (hasUserDownloadedOnce === 'true') {
      videoRef.current.src = RE_DOWNLOAD_VIDEO;
    } else {
      setCookie('appDownloaded', "true")
      videoRef.current.src = FIRST_DOWNLOAD_VIDEO;
    }

    setShowVideo(true);
    setTimeout(() => {
      videoRef?.current?.play();
    }, 2000)
    return;
  }

  const downloadAppAgain = () => {
    setCLicked(true);
    setTimeLeft(0);
    window.location.href = window.oneLinkParams.clickURL;
    window.fbq('trackCustom', 'RestartDownloadApk');

    videoRef.current.src = RE_DOWNLOAD_VIDEO;
    setShowVideo(true);
    setTimeout(() => {
      videoRef?.current?.play();
    }, 2000)
    return;
  }
  useEffect(() => {
    if (clicked) return;
    if (!timeLeft) {
      downloadApp()
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  return (
    <div className={styles.container}>
      <HomePageMetaPixel />
      <video ref={videoRef} autoPlay loop className={styles.video} style={{ display: showVideo ? 'block' : 'none' }}>
      </video>
      {showVideo && <Link to={'/'}><img src={closeIcon} className={`${styles.closeIcon} ${styles.invert}`} /></Link>}

      {!showVideo && <div className={styles.content}>
        <Header forceActive customCrossAction={() => navigate('/')} />
        <img data-aos="fade-up" alt="reviews" src={reviewsImage} className={styles.reviewsImage} />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          background: '#FFFAF6',
          marginTop: 'auto',
          padding: '16px 0 32px'
        }}>
          <img data-aos="fade-up" data-aos-delay="1000" alt="reviews" src={harmImage} style={{
            height: '40vh'
          }} />
        </div>
      </div>
        }
        <div className={styles.buttonsContainer}>
         {!showVideo && <div className={styles.progressBar}>
            <div className={styles.progress} />
          </div>}
         {!showVideo &&  <div className={styles.timer}>{strings.downloadStartText}<span className={styles.timeLeft}>{timeLeft}</span> {strings.seconds}</div>}
          <div className={styles.buttonContainer}>
            <Button onPress={!showVideo ? downloadApp: downloadAppAgain} style={{}} buttonText={showVideo ? "RESTART DOWNLOAD":"START NOW"} backgroundColor={"#009A66"} />
          </div>
        </div>
    </div>
  )
}


export default DownloadApp;