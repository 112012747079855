import { Accordion } from "./components/Accordion";
import HeroImg from './hero.png'
import './faqs.css';
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'

export const FAQsComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.JOIN_US_FAQS);
  registerStrings(strings);
  const faqs = [
    {
      question: strings.q1,
      answer: strings.a1
    },
    {
      question: strings.q2,
      answer: strings.a2
    },
    {
      question: strings.q3,
      answer: strings.a3
    },
  ];
  return (
    <div className="faqs-section-container">
      <div className="faqs-hero-img-container">
        <img data-aos="zoom-in" data-aos-duration="1000" className="faq-hero-img" src={HeroImg} />
      </div>
      <div className="faqs-container">
        <h1>{strings.heading}</h1>
        {faqs.map((faq, index) => (
          <div key={index}>
            <Accordion question={faq.question} answer={faq.answer} />
            {index < faqs.length - 1 && <hr data-aos="fade-up" data-aos-duration="1000" />}
          </div>
        ))}

      </div>
    </div>
  )
}