import styles from './slantHeading.module.css';

export const SlantHeading = ({ prefix, heading }) => {
  return <div className={styles.container}>
    <div className={styles.divider1} />
    <h1 className={styles.prefix}>{prefix}</h1>
    <div className={styles.slantDivider} />
    <h1 className={styles.heading}>{heading}</h1>
    <div className={styles.divider} />
  </div>
}