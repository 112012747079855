import HeroImage from './images/hero.png'
import Check from './images/check.png'
import './checklist.css'
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'

export const CheckList = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.JOIN_US_CHECKLIST);
  registerStrings(strings);
  const CHECKLIST = [
  strings.c1,
  strings.c2,
  strings.c3,
  strings.c4,
  ]
  return (

    <div className="checklist-section-container">
      <h1 data-aos="fade-up" data-aos-duration="1000" className='top-heading'>{strings.heading}</h1>
      <div className="checklist-container">
        <img className='hero-image' src={HeroImage} />
        <div className='checklist'>
          {
            CHECKLIST.map((item, key) => {
              return (
                <div key={key} data-aos="fade-left" data-aos-duration="1000" className='checklist-item'>
                  <img className='check' src={Check} />
                  <p className='check-text'>{item}</p>
                </div>
              )
            })
          }
        </div>
      </div>
      <h2 data-aos="fade-up" data-aos-duration="1000" className='bottom-heading'>
      {strings.mission}
      </h2>
    </div>
  )
}