
import './media.css';

import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import LocalizedStrings from 'react-localization';
function Media() {

  const openMediaCoverageLink = (link) => {
    window.open(link)
  }
  let strings = new LocalizedStrings(LANGUAGE_DATA.HOME_MEDIA);
  registerStrings(strings);

  const MediaCoverage = [
    {
        link: "https://economictimes.indiatimes.com/tech/funding/bgmi-creator-krafton-leads-6-5-funding-round-in-frnd-dating-app/articleshow/88377534.cms",
        text: strings.n1,
        imgUrl: "https://img.etimg.com/thumb/msid-88377512,width-650,imgsize-7584,,resizemode-4,quality-100/frnd.jpg"
    },
    {
        link: "https://inc42.com/buzz/pubg-maker-krafton-invests-6-5-mn-in-gamified-dating-app-frnd/",
        text: strings.n2,
        imgUrl: "https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/krafton_news_4x.webp"
    },
    {
        link: "https://www.moneycontrol.com/news/business/startup/battlegrounds-mobile-india-maker-krafton-leads-6-5m-funding-in-audio-dating-app-frnd-7844701.html",
        text: strings.n3,
        imgUrl: "https://images.moneycontrol.com/static-mcnews/2021/07/dating-app-love-algorithm-alexander-sinn-KgLtFCgfC28-unsplash-770x433.jpg?impolicy=website&width=770&height=431"
    }
]
  return (
    <div className="media-container">
      <div className="media-title">{strings.heading}</div>
      <div className="media-coverage-block" data-aos="fade-up" data-aos-duration="1000">
        { MediaCoverage.map((mediaCoverage, index) => (
          <div key={index} onClick={() => openMediaCoverageLink(mediaCoverage.link)} className="coverage">
          <div><img height="204" width="255" src={mediaCoverage.imgUrl}/></div>
          <div className="media-text">
            {mediaCoverage.text}
          </div>
        </div>
        ))}
      </div>
    </div>
  );
}

export default Media;