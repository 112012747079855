// export const profileConfig = {
//     userData: {
//         'soniya-sen': {
//             bannerImageUrl: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/profile_banner.png',
//             avatarImgUrl: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/profile_avatar.png',
//             name: 'Soniya Sen',
//             age: 26,
//             location: 'Maharashtra',
//             languages: 'हिन्दी, English',
//             followers: 841,
//             reviews: [
//                 {
//                     reviewText: 'Honestly, meri kaafi fun chatting huhi ꧁༒☬sunny☬༒꧂ se',
//                     reviewerName: 'Sunil',
//                     reviewerImage: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/reviewer_1_user.png'
//                 },
//                 {
//                     reviewText: 'Smiley ji ne mujhe apni GF Priti se apne show pr milwaya. Thanks Smiley',
//                     reviewerName: 'Dilip Devgn',
//                     reviewerImage: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/reviewer_3_user.png'
//                 },
//                 {
//                     reviewText: 'Roz smiley se video call krke din ki sari thakan dur ho jati hai',
//                     reviewerName: 'Pranjal',
//                     reviewerImage: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/reviewer_2_user.png'
//                 }
//             ]
//         }
//     }
// };

export const profileConfig = {
    userData: {
        'sneha': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Sneha_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/TO3t53ruqHaFJRIwkSaHiEzKGJcyYOoc.png',
            name: '💙SñëHå💙',
            age: 19,
            location: 'Kolkata',
            languages: 'বাংলা',
            followers: 6778,
            reviews: [
                {
                    reviewText: 'Sneha ne Mujhe Yaha Gf Se Milwaya.Thanks Sneha & FRND🙏',
                    reviewerName: 'Mir aamir',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy01.png'
                },
                {
                    reviewText: 'ब्रेकअप के बाद मैं टूट सा गया था। इन्होंने मोटिवेट किया।',
                    reviewerName: 'Vicky KumaR',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy02.png'
                },
                {
                    reviewText: 'Inhone FRND app pr Gf se milwane me meri Kaffi madad ki.',
                    reviewerName: 'sabir 🥰🥰',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy03.png'
                }
            ]
        },
        'anju': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Pihu_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/girl(17).png',
            name: 'Anju..',
            age: 26,
            location: 'Delhi',
            languages: 'हिन्दी',
            followers: 5335,
            reviews: [
                {
                    reviewText: 'Mai Aapko Kbhi Bhul nhi Sakta. Aapne Mujhe Bre time me Sambhala.',
                    reviewerName: 'honey Singh.',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy04.png'
                },
                {
                    reviewText: 'Ab mere Paas GF hai Roj Meri Ghanto Baat hoti. Thanks Anju🙏',
                    reviewerName: 'ashu_iz_here',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy05.png'
                },
                {
                    reviewText: 'FRND App & Anju ko Kbhi Bhul nhi sakta.😍 Aapne Kaafi Help Ki hai',
                    reviewerName: 'Christyy',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy06.png'
                }
            ]
        },
        'naaz': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Naaz_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/a1IIeNS6cqTXPOCJkT307efnZ5Tqo0JB.png',
            name: '༄𝘚M ᭄✿𝐍𝐚𝐚𝐳',
            age: 20,
            location: 'Jaipur',
            languages: 'हिन्दी',
            followers: 8711,
            reviews: [
                {
                    reviewText: 'अब GF से ऑडियो कॉल पर रोज बात होती है। आपके वजह से संभव हुआ।',
                    reviewerName: 'Raja. Khan',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy07.png'
                },
                {
                    reviewText: 'Breakup ke baad Jb Bura time chl raha tha aapne kaafi Help ki.😊',
                    reviewerName: 'CUTE BOY',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy08.png'
                },
                {
                    reviewText: 'Jb bhi koi suggestion lena hota hai aapk hamesa Help krti hai.',
                    reviewerName: 'rambo',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy09.png'
                }
            ]
        },
        'ektuu': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Ektuu_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/girl(26).png',
            name: 'ektuu',
            age: 23,
            location: 'Karnal',
            languages: 'हिन्दी',
            followers: 6234,
            reviews: [
                {
                    reviewText: 'हमें इतनी प्यारी GF मिली सब आपके वजह से संभव हुआ।☺️',
                    reviewerName: 'Prem ',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy10.png'
                },
                {
                    reviewText: 'Apne Jis tarah se Madad ki aapko Kbhi bhul nhi sakta.',
                    reviewerName: 'prince verm',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy11.png'
                },
                {
                    reviewText: 'Breakup ke Baad mai hopeless ho gaya tha apne kaafi Motivation kiya',
                    reviewerName: '『VeďαnŦ』',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy12.png'
                }
            ]
        },
  
        'aapnokiduniya': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Apno%20ki%20dunia_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/dZaziBtSpa9sTUd7D4CSQldl3nafxW7e.png',
            name: 'Aapno ki duniya',
            age: 20,
            location: 'Indore',
            languages: 'हिन्दी',
            followers: 5559,
            reviews: [
                {
                    reviewText: 'Ab mai Single nhi Mingle hu. Isme aap Kafi bada Role hai.🙏',
                    reviewerName: '☹︎𝕦𝕤𝕠𝕗☹︎ ',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy13.png'
                },
                {
                    reviewText: 'Ab Ghanto Gf se Video Call pr baat krta hu.Aap naa hoti to Sambhaw naa Hota.',
                    reviewerName: 'debjit😊',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy14.png'
                },
                {
                    reviewText: 'Breakup ke Baad aapne mera Patchup karaya.Thanks😍',
                    reviewerName: 'MR.RC RIDER',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy15.png'
                }
            ]
        },
        'hope': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Hope_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/6Nk0PDnYVBBXCW23iLuapRZT2RJTxLsJ.png',
            name: '𝓗𝓸𝓹𝓮❤🤞',
            age: 20,
            location: 'Kolkata',
            languages: 'বাংলা',
            followers: 9803,
            reviews: [
                {
                    reviewText: 'मैं आपको कभी भूल नहीं सकता ।आपने मुझे यहाँ GF से मिलाया।🤗',
                    reviewerName: 'vïléñ ',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy16.png'
                },
                {
                    reviewText: 'FRND App Par Mujhe GF mile. Aap naa hoti to possible naa hota.',
                    reviewerName: 'deep sidhu',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy17.png'
                },
                {
                    reviewText: 'अब मैं काफी खुश रहता हूं मेरे पास प्यारी सी GF जो है। Thanks Hope😊',
                    reviewerName: 'मे के राज Bhai',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy18.png'
                }
            ]
        },
        'flowerpetals': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Flower%20petals_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/girl(17).png',
            name: '🌼FlowerPetals🌼',
            age: 19,
            location: 'Gangtok',
            languages: 'हिन्दी',
            followers: 9048,
            reviews: [
                {
                    reviewText: 'RJ Ho to aapke jaisa Aapke paas har Problems ka Solution rahta hai.',
                    reviewerName: '@sumit',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy19.png'
                },
                {
                    reviewText: 'Breakup ke baad mai kaafi tut chuka tha. Aapne meri kaafi help ki.',
                    reviewerName: 'king___',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy20.png'
                },
                {
                    reviewText: 'Aapko mai kbhi Bhul nhi sakta. Aap kaafi Helpful ho.',
                    reviewerName: 'Karan Mehra',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy21.png'
                }
            ]
        },
        'twinkle': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Twinkle_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/Bew2Znrj7L9wMIUwCvblL4IP03IBewcn.png',
            name: 'Twinkle✴️',
            age: 20,
            location: 'Bhubaneswar',
            languages: 'हिन्दी',
            followers: 7442,
            reviews: [
                {
                    reviewText: 'Aapne Mere Problems ko Samjha Aur mujhe Gf se milwaya. Thanks😍',
                    reviewerName: '@sumit',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy19.png'
                },
                {
                    reviewText: 'GF से काफी बाते होती है कॉल पर आपके बिना संभव नही था।',
                    reviewerName: 'king___',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy20.png'
                },
                {
                    reviewText: 'Aap kaafi acche ho. Aapne GF se milwaya😊',
                    reviewerName: 'Karan Mehra',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy21.png'
                }
            ]
        },
        'rjvijaya': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Vijya_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/ZTleKkbE0XBnm1PhQ6sF7QFuNt7HFOWS.png',
            name: 'RJ _Vijaya',
            age: 25,
            location: 'Nagpur',
            languages: 'हिन्दी',
            followers: 7598,
            reviews: [
                {
                    reviewText: 'Aap naa milte to mai aaj bhi Single rahta. Thanks.',
                    reviewerName: '@Karan Mehra',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy21.png'
                },
                {
                    reviewText: 'Mai kafi lucky hu jise aap jaise RJ ka Guidance mila.',
                    reviewerName: 'ashok09',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy23.png'
                },
                {
                    reviewText: 'Jb se GF se mila hu life change ho gaya hai. Thanks Vijya😍',
                    reviewerName: 'ranjan',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy25.png'
                }
            ]
        },
        'janvi': {
            bannerImageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Janvi_banner.png',
            avatarImgUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/avatar/ils376yK0xmwG5SZBXcgcVxFFOKTxNTX.png',
            name: 'janvi❤️👩‍💼',
            age: 25,
            location: 'Delhi',
            languages: 'हिन्दी',
            followers: 8444,
            reviews: [
                {
                    reviewText: 'Mai kafi lucky hu jise aap jaise RJ ka Guidance mila.',
                    reviewerName: 'bunny',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy22.png'
                },
                {
                    reviewText: 'Aap naa milte to mai aaj bhi Single rahta. Thanks.',
                    reviewerName: 'banna sa',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy24.png'
                },
                {
                    reviewText: 'Aap kaafi acche ho. Aapne GF se milwaya😊',
                    reviewerName: 'ranjan',
                    reviewerImage: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy25.png'
                }
            ]
        }
    }
};

// export const moreFrndsConfig = [
//     {
//         name: 'Soniya Sen',
//         age: 26,
//         location: 'Maharashtra',
//         id: 'soniya-sen',
//         imageUrl: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/real_photo.png'
//     },
//     {
//         name: 'Soniya Sen',
//         age: 26,
//         location: 'Maharashtra',
//         id: 'soniya-sen',
//         imageUrl: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/real_photo.png'
//     },
//     {
//         name: 'Soniya Sen',
//         age: 26,
//         location: 'Maharashtra',
//         id: 'soniya-sen',
//         imageUrl: 'https://frnd-cbt.s3.ap-south-1.amazonaws.com/frnd_media/frnd_website/profile_exp/real_photo.png'
//     }
// ];


export const moreFrndsConfig = [
    {
        name: '💙SñëHå💙',
        age: 19,
        location: 'Kolkata',
        id: 'sneha',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Sneha_dp.png'
    },
    {
        name: 'Anju..',
        age: 26,
        location: 'Delhi',
        id: 'anju',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Pihu_dp.png'
    },
    {
        name: '༄𝘚M ᭄✿𝐍𝐚𝐚𝐳',
        age: 20,
        location: 'Jaipur',
        id: 'naaz',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Naaz_dp.png'
    },
    {
        name: 'ektuu',
        age: 23,
        location: 'Karnal',
        id: 'ektuu',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Ektuu_dp.png'
    },
    {
        name: 'Aapno ki duniya',
        age: 20,
        location: 'Indore',
        id: 'aapnokiduniya',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Apno%20ki%20dunia_dp.png'
    },
    {
        name: '𝓗𝓸𝓹𝓮❤🤞',
        age: 20,
        location: 'Kolkata',
        id: 'hope',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Hope_dp.png'
    },
    {
        name: '🌼FlowerPetals🌼',
        age: 19,
        location: 'Gangtok',
        id: 'flowerpetals',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Petal_dp.png'
    },
    {
        name: 'RJ _Vijaya',
        age: 25,
        location: 'Nagpur',
        id: 'rjvijaya',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Vijya_dp.png'
    },
    {
        name: 'janvi❤️👩‍💼',
        age: 25,
        location: 'Delhi',
        id: 'janvi',
        imageUrl: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/Janvi_dp.png'
    }

];

export const playstoreReviews = [
    {
        text: 'I Love FRND App.♥️ Hme Yaha itni Pyari GF jo Mili.',
        rating: 5,
        handle: 'CUTE BOY',
        reviewerIcon: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy08.png'
    },
    {
        text: 'Yaha Maine pahli baar kisi Girl se baat ki.😊',
        rating: 5,
        handle: 'sabir 🥰🥰',
        reviewerIcon: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy03.png'
    },
    {
        text: 'Iss app se pyar ho gaya hai. Yaha Girls se Khub baate krta hu.',
        rating: 5,
        handle: 'Christyy',
        reviewerIcon: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy06.png'
    },
    {
        text: 'FRND app par Voice & Audio call krne me kaafi mja aata hai.😍',
        rating: 5,
        handle: 'honey Singh.',
        reviewerIcon: 'https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/website/profile/boy04.png'
    }
]