import benefit1 from './benefit1.png'
import benefit2 from './benefit2.png'
import benefit3 from './benefit3.png';
import styles from './benefits.module.css'
export const Benifits = ({textColor}) => {
  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitHeading} style={{color: textColor || 'black'}}>Benefits of FRND coins🔥</div>
      <div className={styles.benefitsList}>
        <div className={styles.benefit} style={{color: textColor || '#7e7e7e'}}>
          <img src={benefit1}  alt='benefit' className={styles.benefitImage}/>
          <div className={styles.benefitText}>Enjoy video + audio call</div>
        </div>
        <div className={styles.benefit} style={{color: textColor || '#7e7e7e'}}>
          <img src={benefit2}  alt='benefit' className={styles.benefitImage}/>
          <div className={styles.benefitText}>Gift your FRNDs</div>
        </div>
        <div className={styles.benefit} style={{color: textColor || '#7e7e7e'}}>
          <img src={benefit3}  alt='benefit' className={styles.benefitImage}/>
          <div className={styles.benefitText}>Add DP frames</div>
        </div>
    </div>
    </div>
  )
}