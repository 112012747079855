import Slider from "react-slick";
import styles from './Carousel.module.css'
import './slick.css'
export const CarouselDesktop = ({use, images=[], style, showDots = true}) => {
  const settings = {
    dots: true && showDots,
    dotsClass: `slick-dots ${styles.slickDots}`,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: false,
    customPaging: function(i) {
      return <div className={`${styles.slickDot} slick-dot`} />
    }
  };
  return (
    <div className={`${styles.carouselContainer} ${use === 'home' && styles.homeCarousel}`} >
      <Slider {...settings}>
         {images.map((image, key) => <img key={key} className={`${styles.carouselImage} `} src={image}/>)}
      </Slider>
    </div>
  );
}