import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
export const TileBlock = ({ imageLink, navigationPath, slug, title, orientation = "vertical", imagePosition = "start" }) => {

  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(navigationPath + slug)} className={styles.container} style={{
      flexDirection: orientation === "vertical" ? "column" : imagePosition === "start" ? "row" : "row-reverse"
    }}>
      <div className={`${styles.imageContainer} ${orientation === "horizontal" ? styles.imageContainerHorizontal: styles.imageContainerVertical}`}>
        <img className={styles.image} src={imageLink} />
      </div>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.cta}>Learn More</p>
      </div>
    </div>
  )
}