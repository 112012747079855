import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./context";

const Modal = () => {
  let { modalContent, handleModal, modal } = React.useContext(ModalContext);
  if (modal) {
    return ReactDOM.createPortal(
      <div
        className="fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{
          background: "rgba(0,0,0,0.8)",
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          display: 'flex',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 100000000000
        }}
      >
        <div style={{
          background: 'white',
          position: 'relative',
          padding: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',

        }} className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800">
          <div
            style={{
              position: 'absolute',
              top: 0,
              alignSelf: 'flex-end',
              right: 0,
              background: 'white',
              width: 16,
              height: 16,
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
            onClick={() => handleModal()}
          >
            &times;
          </div>
          {modalContent}
        </div>
      </div>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default Modal;