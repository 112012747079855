import PIImage from './images/pi.png';
import CommunityImage from './images/community.png';
import AnonImage from './images/anonymity.png';
import BenifitsLarge from './images/vector.png';
import styles from './benefits.module.css'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import LocalizedStrings from 'react-localization';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';

export const BenefitsComponent = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.GAME_EXPERT_JOIN_US_BENEFITS);
  registerStrings(strings);
  const BENIFITS_DATA = [
    {
      icon: PIImage,
      title: strings.b1Title,
      description: strings.b1Text,
    },
    {
      icon: CommunityImage,
      title: strings.b2Title,
      description: strings.b2Text,
    },
    {
      icon: AnonImage,
      title: strings.b3Title,
      description: strings.b3Text,
    },
  ]

  return (
    <div className="container">
      <div className="benefits-content">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          {strings.heading}
        </h1>
        <div className='benefits-container'>
          {
            BENIFITS_DATA.map((benifit, key) => {
              return <div  key={key.toString()} data-aos="fade-up" data-aos-duration="1000" className='benefit-container'>
                <img className='benefit-icon' src={benifit.icon} />
                <div className='benefit-text'>
                  <h3>{benifit.title}</h3>
                  <p dangerouslySetInnerHTML={{__html: benifit.description}}></p>
                </div>
              </div>
            })
          }
        </div>
      </div>
      <div className="benefits-image-container">
        <img data-aos="zoom-in" data-aos-duration="1000" className={styles.benefitsImage + ' benefits-image'} src={BenifitsLarge} />
      </div>

    </div>
  )
}