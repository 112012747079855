import { useEffect, useState } from 'react';
import Header from '../../Components/Common/Header';
import features from './features.svg';
import successIcon from './success.svg';
import coinIcon from './coin.svg';
import failedIcon from './failed.svg';
import processingIcon from './processing.png';
import caret from './caret.svg';
import helpcenter from './helpCenter.svg';
import image1 from './image1.png'
import image2 from './image2.png'
import styles from './styles.module.css';
import { Button } from '../../Components/Common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { checkOrderStatus, getAccessToken } from '../../modules/networkCall';
import { CarouselDesktop } from '../../Components/Common/CorouselDesktop/CarouselDesktop';
import { Benifits } from '../Packages/components/Benifits';
import { useLocation } from 'react-router-dom';
import { deleteCookie, getCookie, setCookie } from '../../modules/cookie';
import { FAQComponent } from '../../Components/Common/FAQs';
import { NEW_LANGUAGE_DATA } from '../../modules/localisation/newTextData';
import LocalizedStrings from 'react-localization';
import { registerStrings } from '../../modules/localisation/LocalisationModule';
import { HomePageMetaPixel } from '../../common/utils';
import { SavingsBanner } from './Banner/Banner';

const NUMBER_OF_RETRIES = 10;
const RETRY_DELAY = 5000;

function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

export const PaymentStatus = () => {
  let strings = new LocalizedStrings(NEW_LANGUAGE_DATA.PAYMENT_STATUS);
  registerStrings(strings);
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState('processing');
  const [numCoins, setNumCoins] = useState(0);
  const [showFAQ, setShowFAQ] = useState(false)
  const [source, setSource] = useState("");
  const [showBanner, setShowBanner] = useState(0);
  const openIntentApp = (status) => {
    if (source === 'web') {
      // pure website flow
      window.location.href = 'intent://frnd.app/#Intent;scheme=https;package=com.dating.for.all;end';
    } else if (source === 'ios') {
      // app flow with source - ios
      window.location.href = `frndother://applink?key~action=show-screen&key~screen-id=all-games&key~pay-status=${status || paymentStatus}`;
    } else if (source === 'web_view') {
      // app flow with source - webview
      window.location.href = 'https://frnd.app/applink?key~action=show-screen&key~screen-id=all-games';
    } else if (source === 'browser') {
      // app flow with source - browser
      window.location.href = 'intent://frnd.app/applink?key~action=show-screen&key~screen-id=all-games#Intent;scheme=https;package=com.dating.for.all;end';
    } else {
      // window.location.href = 'intent://frnd.app/applink?key~action=show-screen&key~screen-id=all-games#Intent;scheme=https;package=com.dating.for.all;end';
      window.location.href = 'intent://frnd.app/applink?key~action=open-app#Intent;scheme=https;package=com.dating.for.all;end';
    }
  }

  const fetchAndSetAuthCookie = async () => {
    try {
      const refreshToken = getCookie('refresh_token');
      const data = await getAccessToken({
        "web_access_token": true,
        "refresh_token": refreshToken
      });
      if (data?.access_token) {
        setCookie('access_token', data.access_token);
      }
    } catch(e) {

    }
  }
  const { search } = useLocation();
  const qp = new URLSearchParams(search)
  const orderStatusHelper = async (num) => {
    if (num > 0) {
      const order_id = qp.get('order_id')
      console.log("trying again", order_id);
      if (order_id) {
        const data = await checkOrderStatus({
          order_id
        });
        deleteCookie('order_source');
        if (data?.error_code) {
          await orderStatusHelper(0);
          return;
        }
        if (data?.status === "SUCCESS") {
          if (data.num_coins) {
            window.fbq('track', 'Purchase', {
              value: data.amount,
              content_ids: [data.coin_package_id],
              currency: 'INR'
            });
            setNumCoins(data.num_coins)
          }
          setTimeout(() => {
            const showBanner = getCookie('show_add_to_hs_banner') === "show";
            if (showBanner && data.savings_amount) {
              setShowBanner(data.savings_amount);
            }
          }, 1000);
          setPaymentStatus('success');
          fetchAndSetAuthCookie();
          return;
        } else if (data?.status === 'FAILED') {
          setPaymentStatus('failed');
          return;
        } else {
          await delay(RETRY_DELAY)
          await orderStatusHelper(num - 1);
        }
      } else {
        setPaymentStatus('failed')
      }

    } else {
      setPaymentStatus('pending')
    }
  }

  const pollOrderStatus = async () => {
    const cookieSource = getCookie('order_source');
    const deviceType = getCookie('device_type');
    if (deviceType) {
      setSource(deviceType)
    } else {
      setSource(cookieSource);
    }

    setPaymentStatus('processing');
    const num = NUMBER_OF_RETRIES;
    await orderStatusHelper(num);
  }

  useEffect(() => {
    if (source && source === 'app') {
      navigate('/webview/purchase-callback');
      return;
    }

    pollOrderStatus();
  }, [])

  return (
    <>
      <div className={styles.desktop}>
        <HomePageMetaPixel />
        <Header forceActive customCrossAction={() => { navigate("/") }} />
        <div className={styles.screenContainer}>
          <div className={styles.leftPane}>
            <CarouselDesktop images={[image1, image2]} />
            <Benifits textColor={'white'} />
          </div>
          <div className={styles.rightPane}>
            <div className={styles.container}>
              {showBanner && <SavingsBanner onClose={() => setShowBanner(0)} savingAmount={showBanner} />}
              {paymentStatus === 'processing' && <div className={styles.statusContainer}>
                <div className={styles.loader} />
                <div className={styles.processingText}>{strings.processing}</div>
                <div className={styles.processingDescription}>{strings.failedDescription}</div>
                <Button className={styles.successButton} onPress={() => openIntentApp('processing')} backgroundColor={'#4AA9FF'} buttonText={'OPEN FRND APP'} style={{
                  width: '90%',
                  boxSizing: 'border-box',
                  marginBottom: 16
                }} />
              </div>}
              {paymentStatus === 'pending' && <div className={styles.statusContainer}>
                <img alt='' src={processingIcon} className={styles.statusIcon} />
                <div className={styles.pendingText}>{strings.processing}</div>
                <div className={styles.processingDescription}>{strings.pendingDescription}</div>
              </div>}
              {paymentStatus === 'success' && <div className={styles.statusContainer}>
                <img alt='' src={successIcon} className={styles.statusIcon} />
                <div className={styles.successText}>{strings.success}</div>
                <div className={styles.coinsContainer}>
                  <img alt='' src={coinIcon} className={styles.coinIcon} />
                  <div className={styles.coins}>{numCoins}</div>
                </div>
                <div className={styles.creditedText}>{strings.coinsCredited}</div>
              </div>}
              {paymentStatus === 'failed' && <div className={`${styles.statusContainer} ${showFAQ && styles.faqOpened}`} >
                <img alt='' src={failedIcon} className={styles.statusIcon} />
                <div className={styles.successText}>{strings.failed}</div>
                {<div className={styles.processingDescription}>{strings.failedDescription}</div>}
                <Button className={styles.successButton} onPress={() => openIntentApp('failed')} backgroundColor={'#4AA9FF'} buttonText={'OPEN FRND APP'} style={{
                  width: '90%',
                  boxSizing: 'border-box',
                  marginBottom: 16
                }} />
              </div>}
              <div className={styles.footer}>
                {paymentStatus === 'success' && <div className={styles.timer}>{strings.appTimerText}</div>}
                {paymentStatus === 'success' && <Button className={styles.successButton} onPress={() => openIntentApp('success')} backgroundColor={'#4AA9FF'} buttonText={'OPEN FRND APP'} style={{
                  width: '90%',
                  boxSizing: 'border-box',
                  marginBottom: 16
                }} />}
                {paymentStatus === 'failed' &&
                  <div className={styles.helpCenterButton} onClick={() => setShowFAQ(!showFAQ)}>
                    <img alt='helcenter' src={helpcenter} className={styles.helpCenterIcon} />
                    <div className={styles.buttonText}>Help Center</div>
                    <img alt='caret' src={caret} className={styles.caretRightIcon} style={{
                      transform: showFAQ ? 'rotate(90deg)' : 'rotate(0deg)'
                    }} />
                  </div>}
                {paymentStatus === 'failed' && <div className={styles.faqContainer} style={{
                  height: showFAQ ? 200 : 0
                }}>
                  <FAQComponent />
                </div>}
                <img alt='' src={features} className={styles.features} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}