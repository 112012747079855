import { BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Header from "../../Components/Common/Header"
import { SafetyHomePage } from "./HomePage"
import styles from './styles.module.css';
import Close from '../../Components/Common/Header/close.svg';
import Menu from '../../Components/Common/Header/menu_black.svg';
import { useLayoutEffect, useState } from "react";
import { COMMUNITIES, RESOURCES, TOPICS } from "./data";
import cta from './cta.svg';
import Footer from "../../Components/Home/Footer";
import { TopicsHome } from "./Topics/Home";
import { ArticleContainer as TopicsArticleContainer } from "./Topics/articles/ArticleContainer";
import { ArticleContainer as CommunitiesArticleContainer } from "./Communities/articles/ArticleContainer";
import { ArticleContainer as ResourcesArticleContainer } from "./Resources/articles/ArticleContainer";
import { CommunitiesHome } from "./Communities/Home";
import { ResourcesHome } from "./Resources/Home";
import { Transparency } from '../Transparency'
import { NavLink } from "react-router-dom";
export const SafetySection = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);
  return (
    <div>
      <Header extraStyles={styles.header} forceActive />
      <SafetyNavBar />
      <div>
        <div className={styles.content}>
          <Routes >
            <Route path="/" element={<SafetyHomePage />} />
            <Route path="transparency" element={<Transparency />} />
            <Route path="/topics" element={<TopicsHome />} />
            <Route path="/communities" element={<CommunitiesHome />} />
            <Route path="/resources" element={<ResourcesHome />} />
            <Route path="/topics/:topicSlug" element={<TopicsArticleContainer />} />
            <Route path="/resources/:slug" element={<ResourcesArticleContainer />} />
            <Route path="/communities/:slug" element={<CommunitiesArticleContainer />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const SafetyNavBar = () => {

  const [isOpened, setIsOpened] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const clickMenuIcon = (icon) => {
    setIsOpened(false);
    navigate("/safety/" + icon)
  }
  const navigate = useNavigate()
  const handleMouseEvent = (e) => {
    setIsHovered(e.type === "mouseenter")
    e.stopPropagation()
  };

  return <div className={styles.navBarContainer}>
    <h3 onClick={() => navigate("")}>Safety Center {isHovered && "jhg"}</h3>
    <div  className={`${styles.menuOptions} ${isOpened && styles.menuOptionsVisible}`}>
      <div onMouseEnter={handleMouseEvent} onMouseOut={handleMouseEvent} to={"topics"} onClick={() => clickMenuIcon("topics")} className={styles.menuOption}>
        <p>Topics</p>
        <div className={styles.subMenuContainer}>
          <div className={styles.subMenuContent}>
            {TOPICS.map((topic) => {
              return (
                <div onClick={(e) => {
                  navigate("/safety/topics/" + topic.slug);
                  e.stopPropagation()
                }} className={styles.subMenuItemContainer}>
                  <div className={styles.subMenuItemHeading}>
                    <h2 className={styles.subMenuItemTitle}>{topic.title}</h2>
                    <img className={styles.subMenuItemCta} src={cta} />
                  </div>
                  <p className={styles.subMenuItemText}>{topic.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div onClick={() => clickMenuIcon("communities")} className={styles.menuOption}>
        <p>Communities</p>
        <div className={styles.subMenuContainer}>
          <div className={styles.subMenuContent}>
            {COMMUNITIES.map((topic) => {
              return (
                <div onClick={(e) => {
                  navigate("/safety/communities/" + topic.slug);
                  e.stopPropagation()
                }} className={styles.subMenuItemContainer}>
                  <div className={styles.subMenuItemHeading}>
                    <h2 className={styles.subMenuItemTitle}>{topic.title}</h2>
                    <img className={styles.subMenuItemCta} src={cta} />
                  </div>
                  <p className={styles.subMenuItemText}>{topic.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div onClick={() => clickMenuIcon("resources")} className={styles.menuOption}>
        <p>Resources</p>
        <div className={styles.subMenuContainer}>
          <div className={styles.subMenuContent}>
            {RESOURCES.map((topic) => {
              return (
                <div onClick={(e) => {
                  navigate("/safety/resources/" + topic.slug);
                  e.stopPropagation()
                }} className={styles.subMenuItemContainer}>
                  <div className={styles.subMenuItemHeading}>
                    <h2 className={styles.subMenuItemTitle}>{topic.title}</h2>
                    <img className={styles.subMenuItemCta} src={cta} />
                  </div>
                  <p className={styles.subMenuItemText}>{topic.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.background} />
    </div>
    <div className={styles.mobileMenuIconContainer}>
      <div onClick={() => setIsOpened(!isOpened)} className={styles.actionIconContainer}>
        {isOpened ? <img src={Close} style={styles.actionIcon} /> :
          <img src={Menu} style={styles.actionIcon} />}
      </div>
    </div>
  </div>
}