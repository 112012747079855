
import styles from './button.module.css';
export const Button = ({ leftImage, buttonText, backgroundColor, style, disabled, onPress, className, shimmer, textColor }) => {
  return (
    <div
      onClick={() => !disabled && onPress && onPress()}
      className={`${className} ${styles.buttonContainer}`}
      style={{ backgroundColor: disabled ? '#C2C7D0' : backgroundColor, ...style }}
    >
       {shimmer && <div className={styles.shineContainer} />}
      {leftImage && <img alt='button-left-img' src={leftImage} className={styles.leftImage} />}
      <div style={{color: textColor || 'white'}} className={styles.buttonText}>{buttonText}</div>
    </div>
  )
}