import Header from '../../Components/Common/Header';
import styles from './styles.module.css';
export const TermsAndConditions = () => {
  return (
    <>
      <Header forceActive />
      <div id="container" className={styles.docContainer}>
        <h1>
          <strong>
            Terms of Use</strong>
        </h1>
        <p>
          Last  updated: 5th December 2023
        </p>
        <p>
          These Terms of Use ("Terms") govern your use of FRND mobile application (collectively, the "Platform") made available by Cold Brew Tech Pvt. Ltd. ("FRND", "Company", "we", "us" and "our"), a private company established under the laws of India having its registered office at 1781, Urban Vault, 19th Main Road, HSR Layout, Bangalore - 560102. The terms "you" and "your" refer to the user of the Platform.
        </p>
        <p>
          If you do not agree with the terms and conditions of these Terms, please do not use this Platform.
        </p>
        <p>
          You and we are required to follow certain rules while you use our Platform. We have listed these rules in this document. Please read these Terms and all other hyperlinks mentioned here carefully. Do remember that by using our Platform, you agree to these Terms.
        </p>
        <h2>
          CHANGES TO TERMS AND SERVICES
        </h2>
        <p>
          Our Platform is dynamic and may change rapidly. As such, we may change the services we provide at our discretion. We may temporarily, or permanently, stop providing Services or any features to you generally.
        </p>
        <p>
          We may remove or add functionalities to our Platform and Services without any notice. However, if we make a change where your consent is required, we will make sure to ask for it. Please be sure to keep visiting this page from time to time to stay updated on our latest changes and developments.
        </p>
        <p>Visit this page to see any changes that we may make and services that we may add or modify, from time to time</p>
        <h2>
          WHO MAY USE OUR SERVICES
        </h2>
        <p>
          Our Platform helps you stay in touch with your friends and enables you to share images, videos, and more in your preferred regional language. ("Service/Services").
        </p>
        <p>
          You may use our Services only if you are capable of forming a binding agreement with us and are legally permitted to use our Services. If you are accepting these Terms on behalf of a company or any legal persons, then you represent and warrant that you have the authority to bind such an entity to these Terms and effectively "you" and "your" shall refer to the company.<br />Please ensure that you are allowed to use our services under the law.
        </p>
        <h2>
          HOW TO USE OUR SERVICES
        </h2>
        <p>
          To use our Services, you must register on our Platform by entering your phone number and the One-Time-Password sent by us via SMS to your phone number. When you register with us using the FRND mobile application, you also allow us to read your mobile device phone book, your SMS inbox, access your mobile gallery, mobile device storage, and mobile device camera. However, we do not read any information stored on your mobile device and computer without your permission.<br />In order to provide Services to you, we need to access certain features of your mobile device.
        </p>
        <h2>
          PRIVACY POLICY
        </h2>
        <p>
          To effectively provide and introduce any new Services to you, we collect certain information such as your phone number, your gender and your name from you. We may further request and store additional information. Such information is stored securely on amazon web services or "AWS'' cloud servers and the "Google Cloud Platform" cloud servers, thereby also subject to the terms of the AWS and Google Cloud privacy policy. The FRND Privacy Policy explains how we collect, use, share and store the information collected. The FRND Privacy Policy also details your rights under law and how you may control the data you provide us.<br />We have described how we store and use this information in the FRND Privacy Policy.
        </p>
        <h2>
          <strong>
          <b>COINS PURCHASE AND REFUND POLICY</b>
          </strong>
        </h2>
        <p>
          You can now licence virtual gifts/digital goods (such as stickers, gifs, banners etc) to our users ("Gifts"). You can send such gifts by acquiring Coins ("Coins/Coin") using our authorised payment methods and through payment providers made available and authorised by us. Do note that Coins/Gifts cannot be exchanged for cash, or legal tender.
        </p>
        <h3>
        <strong>
          Purchasing Coins
          </strong>
        </h3>
        <ul>
          <li>The price of the Coins will be displayed at the point of purchase. All charges and payments for Coins will be made in the currency specified at the point of purchase through the relevant payment mechanism as set out by us.</li>
          <li>You will be responsible for the payment of any Coins purchased by you. Once your payment has been made, your user account will be credited with the number of Coins purchased.</li>
          <li>Once the coins or any service is purchased successfully on the platform, it cannot be refunded in any manner</li>
        </ul>
        <h3>
          Usage of Coins
        </h3>
        <ul>
          <li>Coins can be used to purchase store items that can be used by users to customise their profiles. Coins can also be used to buy gifts, frames, wallpapers and roses to other users. Coins cannot be exchanged for cash, or legal tender, or currency of any state, region, or any political entity, or any other form of credit.</li>
          <li>Coins can only be used on our Platform and as a part of our Services and cannot be combined or used in conjunction with other promotions, coupons, discounts or special offers, except those designated by us.</li>
          <li>No Coins may be assigned or transferred to any other user of the Platform or any third party. The sale, barter, assignment, or other disposal of any Coins, other than by us, is expressly prohibited. Any violation of this restriction may result in termination of your account on the Platform, forfeiture of the Coins from your account, and/or you may be subject to liability for damages, litigation and transaction costs.</li>
          <li>Accrued Coins do not constitute property and are not transferable: (i) upon death; (ii) as part of a domestic relations matter; or (iii) otherwise by operation of law.</li>
          <li>You agree that we have the right to manage, regulate, control, modify and/or eliminate such Coins, where we have a valid reason to do so such as where we reasonably believe you have violated this Coins Policy, you are in breach of any applicable law or regulation or for legal, security or technical reasons and that we will have no liability to you based on our exercise of this right. If we decide to eliminate Coins from our Services completely, we will do so by providing reasonable notice to you.</li>
        </ul>
        <h3>
          Purchasing Gifts
        </h3>
        <ul>
          <li>Gifts constitute a limited licence to certain features of digital products and services. The conversion/ redemption rate between each Coin and Gift will be displayed on our Platform.</li>
          <li>Published prices include taxes where required under applicable laws of your jurisdiction.</li>
          <li>You agree that we have the absolute right to manage, regulate, control, modify and/or eliminate such exchange rate as we deem fit in our sole discretion, in any general or specific case, and that we will have no liability to you based on our exercise of such right.</li>
          <li>Except as otherwise set out in this Coin Policy, all conversions/ redemptions of Coins into Gifts are final. We do not offer refunds in any manner.</li>
          <li>Gifts cannot be converted into or exchanged for Coins or cash or be refunded or reimbursed by us for any reason.</li>
          <li>Gifts exchanged or received by any user do not constitute property and are not transferable: (i) upon death; (ii) as part of a domestic relations matter; or (iii) otherwise by operation of law.</li>
          <li>We may replace previously exchanged copies of Gifts, if we determine in our sole discretion that the Gifts exchanged or received by a user are corrupted or otherwise damaged. We will not charge additional fees for reissuing a corrupted or otherwise damaged Gift.</li>
          <li>We reserve the right to terminate or take any other appropriate action against you if you are deemed to be abusing the Coins Feature or you are in breach of this Coins Policy.</li>
          <li>You are not permitted to use any Gift or Coin in lieu of receipt of any goods or services from any user on the Platform or otherwise.</li>
        </ul>
        <h2>
          NOTE TO USERS
        </h2>
        <ul>
          <li>Coins/ Gifts are not treated as individual goods to be purchased by users but as licenced access to items issued by the Platform.</li>
          <li>Coins and Gifts associated with your account will be extinguished once you delete your account from our Platform.</li>
          <li>Coins cannot be used for trading on the internet.</li>
          <li>You are solely responsible for the Coins/ Gifts you purchase on the Platform and you agree that we will have no liability or responsibility with respect to such Coins/ Gifts.</li>
          <li>We reserve the right to determine in our sole discretion as to what constitutes a violation.</li>
          <li>We reserve the right, in our sole discretion, to change portions of this Coins Policy at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date on which these terms were last updated.</li>
        </ul>
        <h2>YOUR COMMITMENTS</h2>
        <p>Please note that you will solely bear the costs and consequences of any actions taken by you on the FRND Platform (including any violation of these Terms) along with the commitments given by you below. By using our Services, you agree and acknowledge the following:</p>
        <h4>a. No Impersonation or False Information to be Provided</h4>
        <p>While you do not have to use your actual name on our Platform, you are required to input your correct phone number and gender to use our Services. You will not falsely represent yourself as another person or representative of another person to use our Services.</p>

        <h4>b. Device Security:</h4>
        <p>We have implemented measures to ensure that our Platform is secure. However, there is no guarantee that our Platform is immune to hacking and virus attacks. You will ensure that you have requisite anti-malware and antivirus software on your mobile device and computer to ensure its safety. You will not allow multiple accounts to be linked to your profile through any means, including but not limited to your phone number, PAN number, IP address, UPI ID, bank account number, etc. You will be responsible for all content posted by any account linked through these means.</p>



        <h4>c. Content Removal and Termination:</h4>
        <p>Your usage of our Platform is governed by the FRND Content and Community Guidelines. If any of our users report your content which violates the FRND Content Community Guidelines, we may remove such content from our Platform. In the event that multiple reports are made regarding violation of the FRND Content and Community Guidelines, we may be compelled to terminate your account with us and block you from registering with us. We reserve the right to update these community guidelines at any point as required to ensure the safety of users on the platform. By agreeing to our terms, if you are found in violation of any of our community guidelines, we reserve the right to block your account and any accounts created by you thereafter. In such cases, all your coins or gifts, redeemed or otherwise, will be forfeited and nullified.</p>



        <h4>d. Platform Not to be Used For Anything Unlawful or Illegal</h4>
        <p>You shall not, however, use our Platform to share any content which is obscene, pornographic, harmful for minors, discriminatory, spreading what may be considered as hate speech, inciting any form of violence or hatred against any persons, or violates any laws of India, or is barred from being shared by any laws of India. We reserve the right to remove such content. Please read the FRND Content and Community Guidelines for further details.</p>

        <p>In addition to the above, please note that we may share your information with appropriate law enforcement authorities if we have good-faith belief that it is reasonably necessary to share your personal data or information in order to comply with any legal obligation or any government request; or to protect the rights or prevent any harm to our property or safety, our customers, or public; or to detect, prevent or otherwise address public safety, fraud, security or technical issues. You understand however, that we cannot be held responsible for any actions done by or to you by a third party or user by way of using our Platform.</p>

        <p>We have developed a platform for people to engage in amazing social experiences; please do not share any content which is illegal or causes any harm to the well-being of members of the society or community.</p>

        <h4>e. Content Rights and Liabilities</h4>
        <p>We strongly believe in the freedom of expression and allow you to share photographs, images, videos, music, status updates, and other content on our Platform. We do not have any ownership over any of the content shared by you and the rights in the content remain only with you. You will not use our Platform to violate or infringe upon our or any third-party’s intellectual property rights. Such content is against FRND Content and Community Guidelines and may be removed from the Platform. Further, if you use any content developed by us, then we shall continue to own the intellectual property rights vested in such content.</p>

        <p>By creating/sharing/posting/uploading content using our Services, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide licence to host, use, distribute, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). You may delete your content and/or account at any point. However, your content may continue to appear on the Platform if it has been shared with others. To learn more about how we use information, and how to control or delete your content, please read the FRND Privacy Policy.</p>

        <p>You remain solely responsible for the content you post on our Platform. We do not endorse and are not responsible for any content shared or posted on or through our Platform, and for any consequences resulting out of such sharing or posting. The presence of our logo or any trademark on any content shared by you does not mean that we have endorsed or sponsored your content. Further, we will not be liable for or responsible for the consequences of any transactions made or entered into by you with other users of the Platform or advertisers on the Platform.</p>


        <p>You will always have ownership and responsibilities for the content you share. We will never claim that we have intellectual property rights over your content, but we will have a zero cost, permanent licence to use what you share and post on our Platform.</p>

        <h4>f. Intermediary Status and No Liability</h4>
        <p>We are an intermediary as defined under the Information Technology Act, 2000 and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021. These Terms are published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 that require publishing of the rules and regulations, FRND Privacy Policy, and FRND Terms of Use for accessing and using our Platform. Our role is limited to providing a platform for users to upload, share and display content, created or shared by you and other users.</p>

        <p>We do not control what you or other people may or may not do on the Platform and are thus, not responsible for the consequences of such actions (whether online or offline). We are not responsible for services and features offered by others, even if you access them through our Services. Our responsibility for anything that happens on our Platform is strictly governed by the laws of India and is limited to that extent. You agree that we will not be responsible for any loss of profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising to you or any other person related to these Terms, even if we know they are possible. This includes when we delete your content, information, or account.</p>

        <p>We are an intermediary under Indian law. We do not control what people post on our Platform but we expect everyone to comply with the FRND Content and Community Guidelines.</p>

        <h4>g. You Will Not Attempt to Disrupt or Jeopardise FRND services</h4>
        <p>We have developed a community-driven platform. Therefore, you agree to not interfere with, or use non-public areas of our Platform, Services, and our technical delivery system. You will not introduce any trojans, viruses, any other malicious software, any bots or scrape our Platform for any user information. Additionally, you will not probe, scan, or test the vulnerability of any system, security or authentication measures implemented by us. If you tamper or attempt to tamper with our technological design and architecture, we will terminate your user profile and ban you from using our services.. We may further report such actions to the appropriate law enforcement authorities and proceed against you with legal actions.</p>

        <p>You will not hack into or introduce malicious software of any kind onto our Platform. If you commit such actions, we may remove you from the platform and may report your actions to the police and/or relevant legal authorities.</p>

        <h3>Broadcast</h3>
        <p>As part of the FRND platform we provide the Broadcast feature which allows you to broadcast real-time videos, audios of yourself on the platform. Other users can participate in live videos, audio broadcasted by you thus allowing for real-time communication.</p>

        <p>All content uploaded using the Broadcast feature is subject to the FRND Content and Community Guidelines. We reserve the right to immediately remove any content uploaded using this feature which is in violation of these Terms and the FRND Content and Community Guidelines.</p>

        <p>We may, from time to time, add, remove or alter the functionality of the Broadcast feature. We may also discontinue the Broadcast feature from the FRND platform at any time. We do not warrant that the Broadcast feature will be error-free or always available, the Broadcast feature will always function without disruptions, or any content posted by other users using the Broadcast feature will be accurate.</p>

        <p>We provide the Broadcast feature for you to interact on a real-time basis with other users but you shall not misuse the feature to upload content that is prohibited under the FRND Content and Community Guidelines. We do not warranty the continued availability of this feature.</p>

        <h2>PERMISSIONS YOU GIVE TO US</h2>
        <p>
          You accept these Terms and give us certain permissions so that we can serve you better. Permissions you grant to us are:
        </p>
        <h4>a. Automatic Downloads and Updates</h4>
        <p>We are constantly updating our Platform and Services offered. To use our Platform, you may need to download the FRND mobile application to your mobile device and update it from time to time.</p>
        <p>Applications and software are constantly updated for your use and you will need to install the latest version of the FRND mobile application to your mobile device each time such update is generated</p>
        <h4>b. Permission to Use Cookies</h4>
        <p>We may use cookies, pixel tags, web beacons, mobile device IDs, flash cookies and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites. Please see the FRND Cookie Policy for more information regarding the use of cookies and other technologies described in this section, including regarding your choices relating to such technologies.</p>
        <p>All websites use cookies and store them on your web browser so that usage information can be stored and logged in your browser. For more details, please read the FRND Cookie Policy.</p>
        <h4>c. Data Retention</h4>
        <p>We shall have the right to retain certain information regarding your usage of the Platform. Please view the FRND Privacy Policy for further information relating to the collection, processing, storage and use of your information by us.</p>
        <p>You grant us the right to process, store and retain information relating to you and provided by you. Please see the Privacy Policy for further information.</p>


        <h2>OUR AGREEMENT AND WHAT HAPPENS IF WE DISAGREE</h2>
        <h4>a. Who Has Rights Under These Terms</h4>
        <p>The rights and obligations under these Terms are granted only to you and shall not be assigned to any third party without our consent. However, we are permitted to assign our rights and obligations under these Terms to others. This can happen when, for example, we enter into a merger with another company and create a new company.</p>
        <h4>b. How Will We Handle Disputes</h4>
        <p>In all cases, you agree that disputes will be subject to the laws of India and the courts of Bangalore shall have exclusive jurisdiction over all such disputes.</p>

        <h2>GRIEVANCE REDRESSAL MECHANISM</h2>
        <p>
          As part of our commitment to the privacy and safety of our users, we continue to work alongside the government authorities in order to keep our users safe. We have appointed a Grievance Officer, who can be contacted directly if a user has a concern about their FRND experience. We have put together a robust Grievance Redressal Mechanism in place to help resolve any concerns or complaints raised by you with respect to FRND.
        </p>
        <h4>
          Various mechanisms for grievance redressal are given below:
        </h4>
        <ol>
          <li>You can report user profiles and raise complaints for content which violate our community guidelines. You can select the appropriate reason and click on the report option</li>
          <li>You can send an email to care@FRND.app with your concern or complaint.</li>
        </ol>
        <p>
          You can also contact the Grievance Officer in relation to the following policies, or any other concerns that you may have with respect to:
        </p>
        <ol type='A'>
          <li>FRND Terms of Service</li>
          <li>FRND Privacy policy</li>
          <li>Questions about your account</li>
        </ol>
        <p>
          We have a Grievance Officer to address your concerns regarding data safety, privacy, and Platform usage concerns. We will resolve the issues raised by you within 45 (forty-five) days from receiving them. We have created a method for you to get in touch with us and for us to address your concerns.
        </p>
        <h3>
          You may contact the Grievance Officer at any of the following:
        </h3>
        <p>
          Mr. Harshvardhan Chhangani
        </p>
        <p>
          {"Email: "}
          <a href="mailto:coldbrewtech@gmail.com">coldbrewtech@gmail.com</a>
        </p>
        <p>
          Address: FRND (Cold Brew Tech Pvt. Ltd.)
        </p>
        <p>Urban Vault Site No. 1781 19th Main Road</p>

        <p>Vanganahalli, 1st Sector HSR Layout</p>

        <p>Bangalore 560102</p>

        <p>Note - Kindly send all user related grievances to the above mentioned email ID, in order for us to process and resolve the same in an expeditious manner</p>

        <h2>LIMITATION OF LIABILITY</h2>
        <p>We do not assume any liability with respect to any loss or damage, arising directly or indirectly due to any inaccuracy or incompleteness of any information or a breach of any warranty or guarantee due to the actions of any user of the Platform.</p>
        <p>The Platform and Services are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. We do not warrant the quality of the Services or the Platform including its uninterrupted, timely, secure or error-free provision, continued compatibility on any device, or correction of any errors.</p>
        <p>In no event shall we, or any of our affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, service providers, and suppliers be liable for any special, incidental, punitive, direct, indirect or consequential damages suffered as a consequence of a breach of the Terms by another user or arising out of the use of or the reliance on any of the Services or the Platform.</p>
        <p>In the event any exclusion contained herein is held to be invalid for any reason and we or any of our affiliate entities, officers, directors or employees become liable for loss or damage, then, any such liability shall be limited to not exceeding the charges or amounts paid to us for use of the Platform or the Services in the month preceding the date of the claim.</p>
        <p>
          ·  If the Company or substantially all of its assets are acquired by a third party, in which case personal  data held by it about its customers will be one of the transferred assets. If we are involved in a merger,  acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred  or become subject to a different Privacy Policy, we will notify you in advance so you can opt out of any  such new policy by deleting your account before transfer.
        </p>
        <h2>INDEMNIFICATION</h2>
        <p>You agree to indemnify, defend and hold harmless us, and our subsidiaries, affiliates and agents and their respective officers, directors, employees, successors and assigns from and against any claim, proceeding, loss, damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind arising out of:</p>

        <p>(i) your access to or use of the Platform and Services;</p>

        <p>(ii) any breach by you of your obligations under this Agreement;</p>

        <p>(iii) your violation of the rights of any third party, including any infringement of intellectual property, or of any privacy or consumer protection right;</p>

        <p>(iv) any violation of law or contractual obligation and any claims, demands, notices pursuant to such violation;</p>

        <p>(v) your negligence or willful misconduct.</p>

        <p>This obligation will survive the termination of our Terms.</p>
        <h2>
          UNSOLICITED MATERIAL
        </h2>
        <p>
          We always appreciate feedback or other suggestions. We may use the same without any restrictions or obligation to compensate you for them and are under no obligation to keep them confidential.
        </p>
        <h2>GENERAL</h2>
        <ol>
          <li>If any aspect of these Terms is unenforceable, the rest will remain in effect.</li>
          <li>Any amendment or waiver to our Terms must be in writing and signed by us.</li>
          <li>If we fail to enforce any aspect of these Terms, including reporting any illegal or impermissible actions to appropriate law enforcement authorities or blocking or suspending your profile, such failure to enforce our rights will not be a waiver by us.</li>
          <li>We reserve all rights not expressly granted to you.</li>
        </ol>
      </div>
    </>
  )
}