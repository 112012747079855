export const Blog = () => {
  return (
   <div id="retainable-rss-embed" 
data-rss="https://medium.com/frndapp"
data-maxcols="3" 
data-layout="grid" 
data-poststyle="inline" 
data-readmore="Read the rest" 
data-buttonclass="btn btn-primary" 
data-offset="-100"></div>

  )
}
