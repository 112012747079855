import Community from './images/community.png'
import Rupees from './images/indian-rupees.png'
import Magnifying from './images/magnifying-glass.png'
import LocalizedStrings from 'react-localization'
import { LANGUAGE_DATA } from '../../../modules/localisation/textData'
import { registerStrings } from '../../../modules/localisation/LocalisationModule'
import styles from './styles.module.css';

export const Motto = () => {
  let strings = new LocalizedStrings(LANGUAGE_DATA.CELEB_MOTTO);
  registerStrings(strings);
  const GRID_ITEMS = [
    {
      title: strings.title1,
      description: strings.description1,
      image: Rupees
    },
    {
      title: strings.title2,
      description: strings.description2,
      image: Community
    },
    {
      title: strings.title3,
      description: strings.description3,
      image: Magnifying
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.mottoContainer}>
        <h2>{strings.motto}</h2>
      </div>
      <div className={styles.gridContainer}>
        {
          GRID_ITEMS.map(({ title, description, image }) => {
            return <div className={styles.gridItem}>
              <img alt="grid_item" src={image} />
              <div className={styles.gridContent}>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
          })
        }
      </div>
    </div>

  )
}