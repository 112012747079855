import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import { Carousel } from "../../Components/Common/Corousel/Carousel";
import styles from './packages.module.css';
import image1 from './image1.png';
import desk1 from './desk1.webp';
import image2 from './image2.png';
import desk2 from './desk2.webp';
import { PackageList } from "./components/PackageList";
import { Benifits } from "./components/Benifits";
import helpIcon from './helpIcon.png'
import { FAQComponent } from "../../Components/Common/FAQs";
import { CarouselDesktop } from "../../Components/Common/CorouselDesktop/CarouselDesktop";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getCookie } from "../../modules/cookie";

const PackagesScreen = () => {

  const navigate = useNavigate();

  useEffect(() => {

    const token = getCookie('access_token');
    if (token) {

    } else {
      navigate("/")
    }

  }, [])

  return (
    <>

      <div className={styles.desktop}>
        <Header forceActive logoText="FRND Coins" customCrossAction={() => { navigate('/') }} />
        <div className={styles.screenContainer}>
          <div className={styles.leftPane}>
            <CarouselDesktop images={[image1, image2]} />
            <Benifits textColor={'white'} />
          </div>
          <div className={styles.rightPane}>
            <h2  className={styles.heading}>Select Coin Package</h2>
            {window.innerWidth > 600 && <PackageList />}
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.homeContainer}>
          <div className={styles.carouselWithHeader}>
            <Header logoText="FRND Coins" customCrossAction={() => { navigate('/') }} />
            <Carousel style={{
              marginBottom: 16
            }} height={20} images={[desk1, desk2]} />
            <h3 className={styles.mobileHeading}>Select Coin Package</h3>
            {window.innerWidth < 600 && < PackageList />}
          </div>
          <Benifits />
          <h2 style={{ textAlign: 'center' }}>Help Center</h2>
          <FAQComponent />
        </div>
      </div>
    </>
  )
}


export default PackagesScreen;