import { useLocation } from "react-router-dom";
import Footer from "../Home/Footer";
import { BenefitsComponent } from "./Benefits";
import {  Motto } from "./Motto";
import { FAQsComponent } from "./FAQs";
import { FormComponent } from "./Form";
import { HeroComponent } from "./Hero";
import { useEffect } from "react";
import Header from "../Common/Header";
import { StarTrainerMetaPixel } from "../../common/utils";


const JoinUsCeleb = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
    <StarTrainerMetaPixel />
      <Header forceActive />
      <HeroComponent />
      <BenefitsComponent />
      <Motto />
      <FAQsComponent />
      <Footer />
    </>
  )
}

export default JoinUsCeleb;