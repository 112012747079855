import './features.css';
import Avatar from './aiavatar.png'
import Community from './moderated.png'
import BlindDate from './blind_date.png';
import Gifting from './gift.png';
import features from './features.png';
import { LANGUAGE_DATA } from '../../../modules/localisation/textData';
import { registerStrings } from '../../../modules/localisation/LocalisationModule';
import LocalizedStrings from 'react-localization';

function Features() {
  let strings = new LocalizedStrings(LANGUAGE_DATA.HOME_FEATURES);
  registerStrings(strings);
  return (
    <div className="features-container" id="features-container">
      <div className="feature-heading">{strings.heading}</div>
      <div className="features-section" data-aos="fade-up" data-aos-duration="1000">
        <div style={{width: '100%'}}>
          <div className="feature">
            <div className="bullet"><img width="64" height="64" src={BlindDate} /></div>
            <div className="feature-bold-text"><span>{strings.f1}</span></div>
          </div>
          <div className="feature">
            <div className="bullet"><img width="64" height="64" src={Gifting} /></div>
            <div className="feature-bold-text"><span>{strings.f2}</span></div>
          </div>
          <div className="feature">
            <div className="bullet"><img width="64" height="64" src={Community} /></div>
            <div className="feature-bold-text"><span>{strings.f3}</span></div>
          </div>
          <div className="feature">
            <div className="bullet"><img width="64" height="64" src={Avatar} /></div>
            <div className="feature-bold-text"><span>{strings.f4}</span></div>
          </div>
        </div>
        <div className="feature-img-section">
          <img height="400" src={features} />
        </div>
      </div>
      </div>
      );
}

      export default Features;