import styles from './hero.module.css';
import title from './images/title.svg';
export const HeroComponent = () => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.leftContainer}>
        {/* <h1 className={styles.heroHeading}>FRND <span style={{ color: '#FFC700' }}>Associate </span></h1>
        <h1 className={styles.heroHeading}><span style={{ color: '#FFC700' }}>Product Manager</span></h1>
        <h1 className={styles.heroHeading}>Program</h1> */}
        <img data-aos="fade-up" data-aos-duration="1000" src={title} alt='title' />
        <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250" className={styles.subtitle}>Your chance to transition into product ⚡</p>
        <button data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" onClick={() => window.location.href = "https://form.typeform.com/to/sp1j82sU"} className={styles.button}>APPLY NOW</button>
      </div>
      <div className={styles.rightContainer}>
        <p data-aos="fade-up" data-aos-duration="1000">Join FRND's APM Program—<span style={{ color: '#FFC700' }}>a 6-month action-packed boot camp </span>designed to shape Bharat’s future product leaders! Here’s what’s in store for you</p>
        <ul>
          <li data-aos="fade-up" data-aos-duration="1000"><span style={{ color: '#FFC700' }}>Exclusive access</span> to our founders, top investors, and visionary product leaders.</li>
          <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">Master the art of making high impact products for Bharat that <span style={{ color: '#FFC700' }}></span>solve real-world challenges</li>
          <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000"><span style={{ color: '#FFC700' }}>Lead dynamic, cross-functional teams </span>in engineering, business, and more, to drive real value.</li>
        </ul>
        <p   data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1500" className={styles.italics}>Ready to create, innovate, and make waves? This is your chance!</p>
      </div>
      <button onClick={() => window.location.href = "https://form.typeform.com/to/sp1j82sU"} className={styles.button}>APPLY NOW</button>
    </div>
  )
}