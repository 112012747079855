import RatingIcon from '../../../../images/rating.png';

function Rating({rating}) {
    return (
        <div>
            {Array.from({ length: rating}).map((val, index) => (
                <img key = {index} style={{marginRight: 4 }} height="25" width="25" src={RatingIcon} />
            ))}
        </div>
    );
}

export default Rating;